// General routes
export const DASHBOARD = '/dashboard'
export const LOGIN = '/login'
export const LOGIN_2 = '/users/sign_in'
export const LOGIN_OAUTH = '/oauth'
export const DISTRIBUTOR_LOGIN = '/distributor/login'
export const SIGN_UP = '/sign_up'
export const SIGN_UP_2 = '/signup'
export const SIGN_UP_3 = '/users/sign_up'
export const PASSWORD_RECOVERY = '/password_recovery'
export const PASSWORD_RESET = '/password_reset'
export const UNLOCK_ACCOUNT = '/unlock_account'
export const LOGOUT = '/logout'
export const PAYMENT_REQUIRED = () => `/payment_required`
export const USER_PROFILE = () => `/user/profile`
export const NOTIFICATIONS = '/notifications'

// Onboarding routes
export const ONBOARDING_WELCOME = () => `/onboarding/welcome`
export const ONBOARDING_ROLE = () => '/onboarding/role'
export const ONBOARDING_PERSONALIZE = () => '/onboarding/personalize'
export const ONBOARDING_CONGRATULATIONS = () => '/onboarding/congratulations'
export const ONBOARDING_FIRST_STEPS = () => '/first_steps'

// Organizer routes
export const ORGANIZER_COMPANY_INDEX = () => `/organizer/companies`
export const ORGANIZER_COMPANY_PAYMENT = (companyId = ':companyId') =>
  `/organizer/companies/${companyId}/payment`
export const ORGANIZER_USERS = () => `/organizer/users`
export const ORGANIZER_GENERAL_ACTIONS = () => `/organizer/actions`
export const ORGANIZER_PARTNER_INDEX = () => `/organizer/partners`
export const ORGANIZER_PARTNER_SHOW = (partnerId = ':partnerId') =>
  `/organizer/partners/${partnerId}`
export const ORGANIZER_PARTNER_EDIT_PAYMENT = (partnerId = ':partnerId') =>
  `/organizer/partners/${partnerId}/payment`
export const ORGANIZER_AFFILIATIONS = () => `/organizer/affiliations`
export const ORGANIZER_WORKER_SHOW = (affiliationId = ':affiliationId') =>
  `/organizer/affiliations/${affiliationId}`

// Period routes
export const PERIOD_PAY_PAYROLL = (complement = '') =>
  `/pay_payroll${complement}`
export const PERIOD_GENERATED_PAYROLL = () => '/pay_payroll/generated_payroll'
export const PERIOD_PAY_ELECTRONIC_PAYROLL = (periodId = ':periodId') =>
  `/periods_history/electronic_payroll/${periodId}`
export const PERIOD_ELECTRONIC_PAYROLL_WORKER_HISTORY = (
  periodId = ':periodId',
  workerId = ':workerId'
) =>
  `/periods_history/electronic_payroll/${periodId}/worker_history/${workerId}`
export const PERIOD_PAY_PREVIOUS_PAYROLL = (periodId = ':periodId') =>
  `/periods_history/${periodId}`
export const PERIOD_PREVIOUS_GENERATED_PAYROLL = (periodId = ':periodId') =>
  `/periods_history/${periodId}/generated_payroll`
export const PERIOD_PAYROLL_VIEW_DETAILS = (
  payrollId = ':payrollId',
  socialBenefitsId = ':socialBenefitsId'
) => `/pay_payroll/${payrollId}/view_details/${socialBenefitsId}`
export const PERIOD_PREVIOUS_PAYROLL_VIEW_DETAILS = (
  periodId = ':periodId',
  payrollId = ':payrollId',
  socialBenefitsId = ':socialBenefitsId'
) =>
  `/periods_history/${periodId}/${payrollId}/view_details/${socialBenefitsId}`
export const PERIOD_HISTORY = (complement = '') =>
  `/periods_history${complement}`
export const PERIOD_NOVELTIES_HISTORY = (periodId = ':periodId') =>
  `/pay_payroll/${periodId}/novelties_history`
export const PERIOD_PREVIOUS_PAYROLL_NOVELTIES_HISTORY = (
  periodId = ':periodId'
) => `/periods_history/${periodId}/novelties_history`
export const PERIOD_SOCIAL_BENEFIT_PERIODS = (
  periodId = ':periodId',
  socialBenefitPeriodId = ':socialBenefitPeriodId'
) => `/pay_payroll/${periodId}/social_benefits_period/${socialBenefitPeriodId}`
export const PERIOD_PREVIOUS_PAYROLL_SOCIAL_BENEFIT_PERIODS = (
  periodId = ':periodId',
  socialBenefitPeriodId = ':socialBenefitPeriodId'
) =>
  `/periods_history/${periodId}/social_benefits_period/${socialBenefitPeriodId}`
export const PERIOD_DECREE_376_INDEX = (
  periodId = ':periodId',
  decree376Id = ':decree376Id'
) => `/pay_payroll/${periodId}/decree376/${decree376Id}`
export const PERIOD_PREVIOUS_PAYROLL_DECREE_376_INDEX = (
  periodId = ':periodId',
  decree376Id = ':decree376Id'
) => `/periods_history/${periodId}/decree376/${decree376Id}`
export const PERIOD_SOCIAL_SECURITY_SHOW = (periodId = ':periodId') =>
  `/pay_payroll/${periodId}/social_security`
export const PERIOD_PREVIOUS_PAYROLL_SOCIAL_SECURITY_SHOW = (
  periodId = ':periodId'
) => `/periods_history/${periodId}/social_security`
export const PERIOD_SOCIAL_SECURITY_TRANSACTION_RESPONSE = () =>
  `/social_security_integration_payment/transaction_response`

// Worker routes
export const WORKER_SHOW = (workerId = ':workerId') => `/workers/${workerId}`
export const WORKER_INDEX = (complement = '') => `/workers${complement}`
export const WORKER_NEW = (complement = '') => `/workers/new${complement}`
export const WORKER_IMPORT = (complement = '') => `/workers/import${complement}`
export const WORKER_PAYMENTS_HISTORY = (workerId = ':workerId') =>
  `/workers/${workerId}/payments_history`
export const WORKER_HOLIDAYS_HISTORY = (workerId = ':workerId') =>
  `/workers/${workerId}/holidays_history`
export const WORKER_NOVELTIES_HISTORY = (workerId = ':workerId') =>
  `/workers/${workerId}/novelties_history`
export const WORKER_CONTRACT_INDEX = (workerId = ':workerId') =>
  `/workers/${workerId}/contracts`
export const WORKER_CONTRACT_DETAIL_INDEX = (
  workerId = ':workerId',
  contractId = ':contractId'
) => `/workers/${workerId}/contracts/${contractId}/detail`
export const WORKER_CONTRACT_WAGE_INDEX = (
  workerId = ':workerId',
  contractId = ':contractId'
) => `/workers/${workerId}/contracts/${contractId}/wages`
export const WORKER_CONTRACT_TERMINATION_NEW = (
  workerId = ':workerId',
  contractId = ':contractId'
) => `/workers/${workerId}/contracts/${contractId}/termination/new`
export const WORKER_CONTRACT_TERMINATION_EDIT = (
  workerId = ':workerId',
  contractId = ':contractId'
) => `/workers/${workerId}/contracts/${contractId}/termination/edit`
export const WORKER_CONTRACT_SOCIAL_BENEFITS_INDEX = (
  workerId = ':workerId',
  contractId = ':contractId'
) => `/workers/${workerId}/contracts/${contractId}/social_benefits`
export const WORKER_CONTRACT_SOCIAL_BENEFITS_SHOW = (
  workerId = ':workerId',
  contractId = ':contractId',
  socialBenefitId = ':socialBenefitId'
) =>
  `/workers/${workerId}/contracts/${contractId}/social_benefits/${socialBenefitId}`
export const WORKER_LOAN_INDEX = (workerId = ':workerId') =>
  `/workers/${workerId}/loans`
export const WORKER_LOAN_SHOW = (workerId = ':workerId', loanId = ':loanId') =>
  `/workers/${workerId}/loans/${loanId}`
export const WORKER_BENEFITS_SHOW = () => `/workers/benefits`
export const WORKER_WAGES_UPDATE = () => `/workers/wages`
export const WORKER_AFFILIATIONS_INDEX = () => `/workers/affiliations`
export const WORKER_AFFILIATIONS_NEW = () => `/workers/affiliations/new`

// Human resources routes
export const NOVELTIES_REQUEST_HISTORY = () => `/novelties_request_history`

// Worker routes (worker profile)
export const WORKER_PROFILE_PAYMENTS_HISTORY = () => `/payments_history`
export const WORKER_PAYMENT_DETAIL = (payrollId = ':payrollId') =>
  `/payments_history/${payrollId}/view_details`
export const WORKER_PROFILE_SHOW = () => `/profile`
export const WORKER_PROFILE_BENEFITS = () => `/benefits`
export const WORKER_BENEFITS_CREDIT_FORM = () => `/benefits/credits/application`
export const WORKER_BENEFITS_CREDIT_SIMULATOR = () =>
  `/benefits/credits/simulator`

// Company routes
export const COMPANY_INDEX = (complement = '') => `/companies${complement}`
export const COMPANY_SHOW = (companyId = ':companyId', complement = '') =>
  `/companies/${companyId}${complement}`
export const COMPANY_EDIT = (companyId = ':companyId') =>
  `/companies/${companyId}/edit`

// Settings routes
export const SETTINGS = () => `/settings`
export const SETTINGS_ADVANCED = () => `/settings/advanced`
export const SETTINGS_USERS = () => `/settings/users`
export const SETTINGS_WORKERS_BENEFITS = () => `/settings/workers_benefits`
export const SETTINGS_COMPANY_HISTORY = () => `/settings/company_history`
export const SETTINGS_ACCOUNTING = () => `/settings/accounting`
export const SETTINGS_INTEGRATION_INDEX = () => `/settings/integrations`
export const SETTINGS_INTEGRATION_ALEGRA_ACCOUNTING = () =>
  `/settings/integrations/alegra/accounting`
export const SETTINGS_SOCIAL_SECURITY_INDEX = () =>
  `/settings/integrations/social_security_payments_history`
export const SETTINGS_ELECTRONIC_PAYROLL = () => `/settings/electronic_payroll`

// Subscription routes
export const SUBSCRIPTION = () => `/subscription`
export const SUBSCRIPTION_STATUS = () => `/subscription_detail`
export const SUBSCRIPTION_END_FREE = () => `/subscription/end_free`
export const SUBSCRIPTION_PAYMENTS = () => `/subscription_detail/payments`
export const END_FREE_LANDING_PAGE = () => '/end_free'

// Holistic Payroll
export const DISABILITIES_CLAIMS = () => '/holistic_payroll/disabilities_claims'
export const FINANCIAL_COACHING = () => '/holistic_payroll/financial_coaching'
