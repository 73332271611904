import { useRef } from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { useUser } from 'components/App/UserContext/useUser'
import { getTabsData } from 'components/Subscription/SubscriptionFormFields/helpers'

import { getCompanyId } from 'utils/company'
import { getDirtyValues } from 'utils/form'
import { isObjectEmpty } from 'utils/general'
import { useCompanyService } from 'utils/hooks/company/companyService'
import usePaymentMethodService from 'utils/hooks/subscription/paymentMethodService'
import useErrorHandler from 'utils/hooks/useErrorHandler'

import { DASHBOARD, SUBSCRIPTION_STATUS } from 'config/routes'

import { getPaymentData } from '../Index/helpers'
import useTransactionResponseModal from './TransactionResponseModal/useTransactionResponseModal'
import useSubscription from './useSubscription'

const usePayment = ({
  onCancel,
  plan = {},
  extraWorkers = 0,
  subscriptionChange,
  newSubscriptionType,
  isFromExtraWorkersModal,
  subscriptionTypeValidators,
  isEditingPaymentMethod,
  currentPlanPayment,
  isFromUpdateMethodInfo,
  openUpdatePaymentMethodInfoModal,
  isFromPaywallView,
}) => {
  const { subscription } = useSubscription()
  const queryClient = useQueryClient()
  const formRef = useRef()
  const { handleError } = useErrorHandler()
  const companyId = getCompanyId()
  const subscriptionType = subscription?.type
  const { openTransactionResponseModal } = useTransactionResponseModal()
  const navigate = useNavigate()
  const { company, refreshCompany } = useUser()

  const { paymentMethodMutation } = usePaymentMethodService({
    queryOptions: {
      enabled: false,
    },
  })

  const { companyMutation } = useCompanyService({
    queryOptions: {
      enabled: false,
    },
  })

  const tabsData = getTabsData(
    subscription,
    isEditingPaymentMethod,
    currentPlanPayment
  )

  const handleForm = (formHandler) => {
    if (formRef.current) {
      formRef.current?.[formHandler]()
    }
  }

  const callbackSuccess = async (
    response,
    selectedPaymentMethod,
    isFromSubscriptionPaymentView
  ) => {
    // Takes the user to pse website in order to complete the payment
    if (selectedPaymentMethod === 'pse') {
      window.location.href = response.bank_url
      return
    }

    // Gives the user fresh information regarding subscription and company
    await refreshCompany()
    queryClient.invalidateQueries(['getAllPayments', companyId])

    if (isFromUpdateMethodInfo || isEditingPaymentMethod) {
      onCancel && onCancel()

      openUpdatePaymentMethodInfoModal({
        actionMessage: 'successful_update',
        selectedPaymentMethod,
        isFromPaymentMethodCard: isEditingPaymentMethod,
      })

      return
    }

    // To avoid bugs regarding current plan after getting a new one
    if (response.payment_status === 'APPROVED' && subscriptionChange) {
      queryClient.invalidateQueries(['getPlans', companyId])
    }

    if (isFromSubscriptionPaymentView) {
      if (response.payment_status === 'APPROVED') {
        navigate(SUBSCRIPTION_STATUS())
      }
      if (response.payment_status === 'PENDING') {
        navigate(DASHBOARD)
      }
    }

    // Closes payment modal (if opened) and opens Transaction response modal
    onCancel && onCancel()

    openTransactionResponseModal({
      subscriptionTypeValidators,
      transactionStatus: response.payment_status,
      isExtraworkersPayment: isFromExtraWorkersModal,
    })
  }

  // Closes payment modal (if opened) and opens Transaction response modal or displays error message
  const callbackError = (error) => {
    onCancel && onCancel()
    // Errors related to payment providers (24) or API problems (1101)
    if (
      error.errors?.[0].code.startsWith('24') ||
      error.errors?.[0].code === '1101'
    ) {
      openTransactionResponseModal({
        transactionStatus: 'DECLINED',
        isExtraworkersPayment: isFromExtraWorkersModal,
      })
    } else {
      handleError(error, null, {
        notistackOptions: { preventDuplicate: true },
      })
    }
  }

  const createPayment = async (
    selectedPaymentMethod,
    paymentData,
    isFromSubscriptionPaymentView,
    values,
    isFromPaymentModal
  ) => {
    const companyValues = {
      name: values?.company_form?.name,
      phone: values?.company_form?.phone,
      email: values?.company_form?.email,
      document_type: values?.company_form?.document_type,
      id_number: values?.company_form?.id_number,
    }

    const paymentPromises = []
    let dirtyValues = {}
    if (!isFromPaymentModal)
      dirtyValues = getDirtyValues(company, companyValues)
    const hasDirtyValues = !isObjectEmpty(dirtyValues)

    const submitPaymentMethodMutation = async () => {
      await paymentMethodMutation.mutateAsync(
        {
          paymentMethod: selectedPaymentMethod,
          paymentMethodData: paymentData,
        },
        {
          onSuccess: (response) => {
            callbackSuccess(
              response,
              selectedPaymentMethod,
              isFromSubscriptionPaymentView
            )
          },
          onError: (error) => callbackError(error),
        }
      )
    }

    const submitCompanyMutation = async () => {
      await companyMutation.mutateAsync({
        mutationMethod: 'PATCH',
        company: companyValues,
        companyId,
      })
    }

    const submitPaymentMethodMutationResponse = submitPaymentMethodMutation()
    paymentPromises.push(submitPaymentMethodMutationResponse)

    if (hasDirtyValues) {
      const submitCompanyMutationResponse = submitCompanyMutation()
      paymentPromises.push(submitCompanyMutationResponse)
    }

    try {
      await Promise.all(paymentPromises)
    } catch (error) {
      handleError(error)
    }
  }

  const onSubmit = ({
    values,
    selectedPaymentMethod,
    isCurrentMethodPayment,
    isFromSubscriptionPaymentView,
    isFromPaymentModal,
  }) => {
    const paymentData = getPaymentData({
      values,
      paymentMethod: selectedPaymentMethod,
      planId: plan?.id,
      subscriptionType,
      isPartner: subscription?.partner,
      extraWorkers,
      subscriptionChange,
      newSubscriptionType,
      currentPlanPayment: subscriptionTypeValidators?.currentPlanPayment,
      isPremiumExpiredSubscription:
        subscriptionTypeValidators?.isPremiumExpiredSubscription,
      activeWorkers: subscription.payrolls_size,
      isEditingPaymentMethod,
      isCurrentMethodPayment,
      isFromPaywallView,
      isFromExtraWorkersModal,
    })

    createPayment(
      selectedPaymentMethod,
      paymentData,
      isFromSubscriptionPaymentView,
      values,
      isFromPaymentModal
    )
  }

  const updatePaymentMethod = () => handleForm('handleSubmit')
  const isLoading =
    paymentMethodMutation?.isLoading || companyMutation?.isLoading

  return {
    tabsData,
    onSubmit,
    updatePaymentMethod,
    formRef,
    isLoading,
  }
}

export default usePayment
