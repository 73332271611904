import { useFormikContext } from 'formik'

import FormField from '../FormField/Index'

const AccountNumberField = ({ optional, name, ...props }) => {
  const { values } = useFormikContext()

  return (
    <FormField
      name={name || 'account_number'}
      label="Número de cuenta"
      variant="number"
      decimalScale={0}
      isNumericString
      allowLeadingZeros
      inputProps={{ maxLength: 20 }}
      style={{ fontFamily: 'Roboto Mono, monospace' }}
      format={
        values.account_number?.length >= 9
          ? '###-######-########'
          : '###-######'
      }
      optional={optional}
      {...props}
    />
  )
}

export default AccountNumberField
