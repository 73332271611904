import { Box, Typography } from '@mui/material'

import AleluyaPerson from 'components/UI/AleluyaPerson'
import LinkButton from 'components/UI/Button/LinkButton'

import HelloWoman from 'assets/images/views/common/hello_woman.svg'

export const getInfoMessages = (
  actionMessage,
  openSupportMenu,
  activePayment,
  isFromPaymentMethodCard,
  subscription
) => {
  const paymentType =
    activePayment === 'automatic_debit'
      ? 'cuenta bancaria'
      : 'tarjeta débito/crédito'

  const configMessages = {
    invitation_to_update: {
      title: `¡Yisus! Aún falta tu método de pago`,
      buttonLabel: 'Actualizar método de pago',
      description: (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(1) })}
          >
            En este momento, no tienes un método de pago asociado a tu cuenta.
            Actualiza tus datos en menos de lo que canta un gallo.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Si tienes dudas, puedes contactarnos dando{' '}
            <LinkButton onClick={openSupportMenu}>clic aquí.</LinkButton>
          </Typography>
        </>
      ),
      picture: (
        <Box
          component="img"
          alt="Relájate mientras tienes Aleluya Premium"
          src={HelloWoman}
          width={252}
          height={280}
          sx={{
            objectFit: 'contain',
          }}
        />
      ),
    },
    successful_update: {
      title: `¡Método de pago actualizado!`,
      buttonLabel: 'Cerrar',
      description: isFromPaymentMethodCard ? (
        <Typography variant="body1" color="black.dark">
          En adelante, la <b>{paymentType}</b>, terminada en{' '}
          <b>{subscription.account_number}</b>, será tu método principal para
          realizar los pagos asociados a tu suscripción.
        </Typography>
      ) : (
        <>
          <Typography
            variant="body1"
            color="black.dark"
            sx={(theme) => ({ marginBottom: theme.spacing(2.5) })}
          >
            Tu débito automático a través de una{' '}
            <Typography variant="lead1">{paymentType}</Typography> ha sido
            agregado. Ahora haremos un par de validaciones para verificar que
            hayas ingresado tus datos de forma correcta.
          </Typography>
          <Typography variant="body1" color="black.dark">
            Te estaremos notificando cuando todo este OK!.
          </Typography>
        </>
      ),
      picture: (
        <AleluyaPerson
          color="primary"
          width={330}
          height={280}
          viewBox="0 0 340 400"
        />
      ),
    },
  }

  return configMessages[actionMessage]
}
