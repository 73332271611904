import queryString from 'query-string'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'

import usePremiumFeature from 'components/App/Premium/usePremiumFeature'
import ReacTourProvider from 'components/App/ReacTourProvider'
import usePsePaymentCheck from 'components/Subscription/Atoms/usePsePaymentCheck'
import Icon from 'components/UI/Icon'
import Page from 'components/UI/Page/Page'

import useWorkerService from 'utils/hooks/worker/workerService'

import HiringTable from '../SSAffiliations/OnboardingWorkers/HiringTable'
import { onboardingTourSteps } from '../SSAffiliations/OnboardingWorkers/HiringTable/helpers'
import DownloadIncomesCertificatesModal from './DownloadIncomesCertificatesModal'
import NewWorkerModal from './Modals/NewWorkerModal'
import useNewWorkerModal from './Modals/NewWorkerModal/useNewWorkerModal'
import WorkersTable from './Table'
import useDownloadIncomesCertificatesModal from './useDownloadIncomesCertificatesModal'

export const WorkerIndexContext = createContext()

const WorkerIndex = () => {
  const { showHRFeatures } = usePremiumFeature()
  const { search, state } = useLocation()
  const navigate = useNavigate()

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()

  const queryParams = queryString.parse(search)
  const tabQueryParam = queryParams.tab
  const { addWorkerFromPayPayroll = false } = state || {}
  const isPsePayment = new URLSearchParams(search).get('pse_payment') === 'true'

  const [tab, setTab] = useState(() => {
    if (
      tabQueryParam &&
      ['new', 'active', 'disabled'].includes(tabQueryParam)
    ) {
      if (!showHRFeatures && tabQueryParam === 'new') return 'active'
      return tabQueryParam
    }

    return 'active'
  })
  const [activeContract, setActiveContract] = useState(() => {
    if (state?.filter) return state.filter

    if (tab === 'active') return 'active'

    if (tab === 'disabled') return 'disabled'

    return 'active'
  })

  const { workerQuery } = useWorkerService({
    serviceParams: { queryKey: 'getWorkerTotals' },
  })
  const workerTotalData = useMemo(
    () => workerQuery.data || [],
    [workerQuery.data]
  )
  const paginationRef = useRef(null)

  const { newWorkerModalState, openNewWorkerModal, closeNewWorkerModal } =
    useNewWorkerModal()

  const {
    downloadIncomesCertificatesModalState,
    openDownloadIncomesCertificatesModal,
    closeDownloadIncomesCertificatesModal,
  } = useDownloadIncomesCertificatesModal()

  const handleChange = (_, newValue) => {
    navigate(`?tab=${newValue}`)
    setTab(newValue)

    if (tab !== 'new' && paginationRef.current) {
      paginationRef.current?.resetPagination()
    }

    if (newValue === 'active') {
      setActiveContract('active')
    } else if (newValue === 'disabled') {
      setActiveContract('disabled')
    }
  }

  const handleAddWorkerManually = () => {
    navigate('?tab=new', {
      state: {
        addWorkerManually: true,
      },
    })

    if (showHRFeatures) {
      setTab('new')
    }

    openNewWorkerModal()
  }

  useEffect(() => {
    if (addWorkerFromPayPayroll) {
      navigate(`?tab=${showHRFeatures ? 'new' : 'active'}`, {
        state: {
          addWorkerManually: !!showHRFeatures,
          addWorkerFromPayPayroll: false,
        },
      })

      if (showHRFeatures) {
        setTab('new')
      }
      openNewWorkerModal()
    }
  }, [addWorkerFromPayPayroll, navigate, openNewWorkerModal, showHRFeatures])

  useEffect(() => {
    if (
      (!showHRFeatures && tabQueryParam === 'new') ||
      !search ||
      !['new', 'active', 'disabled'].includes(tabQueryParam)
    ) {
      navigate('?tab=active')
    }
  }, [navigate, search, showHRFeatures, tabQueryParam])

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus) {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  return (
    <TabContext value={tab}>
      <Page
        documentTitle="Personas"
        grid
        rootSx={{ padding: '0 !important' }}
        header={
          <TabList
            onChange={handleChange}
            aria-label="Worker status tabs"
            TabIndicatorProps={{
              sx: (theme) => ({
                backgroundColor: theme.palette.primary.dark,
                borderRadius: '0.25rem',
              }),
            }}
            sx={(theme) => ({
              paddingLeft: theme.spacing(2),
              paddingTop: theme.spacing(2),
              height: '5rem',
              gridColumn: '1 / -1',
              borderTop: `1px dashed ${theme.palette.complementary1.light}`,
              backgroundColor: theme.palette.white.main,
            })}
          >
            <Tab
              sx={(theme) => ({
                display: 'flex',
                marginRight: theme.spacing(2),
                color: theme.palette.black.dark,
                '&:hover': {
                  color: theme.palette.primary.dark,
                  backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                  color: theme.palette.primary.dark,
                },
              })}
              label="Activas"
              value="active"
              icon={
                <Icon
                  name="avatar-approved"
                  basic
                  sx={(theme) => ({
                    fontSize: '1.6rem',
                    marginRight: theme.spacing(0.5),
                  })}
                />
              }
              iconPosition="start"
              disableFocusRipple
              disableRipple
              disableTouchRipple
            />
            {!showHRFeatures ? null : (
              <Tab
                sx={(theme) => ({
                  display: 'flex',
                  marginRight: theme.spacing(2),
                  color: theme.palette.black.dark,
                  '&:hover': {
                    color: theme.palette.primary.dark,
                    backgroundColor: 'transparent',
                  },
                  '&.Mui-selected': {
                    color: theme.palette.primary.dark,
                  },
                })}
                label="En contratación"
                value="new"
                icon={
                  <Icon
                    name="avatar-search"
                    basic
                    sx={(theme) => ({
                      fontSize: '1.6rem',
                      marginRight: theme.spacing(0.5),
                    })}
                  />
                }
                iconPosition="start"
                disableFocusRipple
                disableRipple
                disableTouchRipple
              />
            )}
            <Tab
              sx={(theme) => ({
                display: 'flex',
                color: theme.palette.black.dark,
                '&:hover': {
                  color: theme.palette.primary.dark,
                  backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                  color: theme.palette.primary.dark,
                },
              })}
              label="Liquidadas/Inactivas"
              value="disabled"
              icon={
                <Icon
                  name="avatar-cross"
                  basic
                  sx={(theme) => ({
                    fontSize: '1.6rem',
                    marginRight: theme.spacing(0.5),
                  })}
                />
              }
              iconPosition="start"
            />
          </TabList>
        }
      >
        <WorkerIndexContext.Provider
          value={{
            workerTotalData,
            tab,
            openDownloadIncomesCertificatesModal,
            handleAddWorkerManually,
            activeContract,
            setActiveContract,
            openNewWorkerModal,
          }}
        >
          <Box
            sx={{
              gridColumn: '1 / -1',
            }}
          >
            <TabPanel
              value={tab}
              sx={(theme) => ({
                paddingTop: 0,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
              })}
            >
              {tab !== 'new' ? (
                <WorkersTable paginationRef={paginationRef} />
              ) : (
                <ReacTourProvider
                  showNavigation={false}
                  showCloseButton={false}
                  showBadge={false}
                  steps={onboardingTourSteps}
                >
                  <HiringTable
                    openNewWorkerModal={openNewWorkerModal}
                    newWorkerModalState={newWorkerModalState}
                    isNewWorkerModalOpen={newWorkerModalState.open}
                  />
                </ReacTourProvider>
              )}
            </TabPanel>
          </Box>
          {newWorkerModalState.open ? (
            <NewWorkerModal
              state={newWorkerModalState}
              handleClose={closeNewWorkerModal}
            />
          ) : null}
          {downloadIncomesCertificatesModalState.open ? (
            <DownloadIncomesCertificatesModal
              state={downloadIncomesCertificatesModalState}
              handleClose={closeDownloadIncomesCertificatesModal}
            />
          ) : null}
        </WorkerIndexContext.Provider>
      </Page>
    </TabContext>
  )
}

export default WorkerIndex
