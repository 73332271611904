import { Formik } from 'formik'
import { createContext, useEffect, useMemo, useRef, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Box } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Loading from 'components/UI/Loading/Loading'
import Page from 'components/UI/Page/Page'

import {
  isFreeCompany,
  isPremiumExpiredByCancellationCompany,
} from 'utils/auth'
import { getCompanyId } from 'utils/company'
import { removeSuffix } from 'utils/general'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import {
  DASHBOARD,
  SUBSCRIPTION_END_FREE,
  SUBSCRIPTION_STATUS,
} from 'config/routes'

import usePayment from '../Atoms/usePayment'
import usePsePaymentCheck from '../Atoms/usePsePaymentCheck'
import SubscriptionSummary from './Summary'
import { getInitialValues, getValidationSchema } from './helpers'

export const SubscriptionViewContext = createContext()
SubscriptionViewContext.displayName = 'SubscriptionViewContext'

const Paywall = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const isPsePayment = queryParams.get('pse_payment') === 'true'
  const { isAPartnerChild, company } = useUser()
  const { subscription } = useSubscription()
  const formRef = useRef()
  const companyId = getCompanyId()

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()

  const { modality: modalitySwitch, selectedPlan: planSelected } =
    location?.state || {}

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  const subscriptionExpiredByCancellation =
    isPremiumExpiredByCancellationCompany(subscription)

  const [modality, setModality] = useState('month')
  const [selectedPlan, setSelectedPlan] = useState(planSelected || {})
  const locationState = Boolean(location.state)
  const isSubscriptionPromoPath = location.pathname === SUBSCRIPTION_END_FREE()

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
    queryOptions: {
      enabled: isAFreeCompany && isSubscriptionPromoPath,
    },
  })
  const plansQueryData = useMemo(
    () => plansQuery?.data?.plans || [],
    [plansQuery?.data?.plans]
  )

  const basicPlan = plansQueryData?.find(
    (plan) => removeSuffix(plan.coded_name, -8) === 'premium_per_worker_plan'
  )

  const currentPlan = useMemo(
    () =>
      subscriptionExpiredByCancellation
        ? plansQueryData?.find(
            (plan) =>
              removeSuffix(plan.coded_name, -8) ===
              removeSuffix(subscription.plan.coded_name, -8)
          )
        : {},
    [
      plansQueryData,
      subscription.plan.coded_name,
      subscriptionExpiredByCancellation,
    ]
  )

  const { subscriptionQuery } = useSubscriptionService({
    queryOptions: {
      enabled: locationState || subscriptionExpiredByCancellation,
      onSuccess: ({ data }) => {
        formRef?.current?.setValues({
          ...formRef?.current?.values,
          modality: modality || data?.type,
          plan: selectedPlan?.id,
          workers_number: !data?.partner ? data?.payrolls_size || 1 : 1,
          isPartner: data?.partner,
        })

        if (data?.payment_category === 'clara_payment') {
          formRef?.current?.setValues({
            ...formRef?.current?.values,
            modality: 'year',
            workers_number: 20,
          })
        }
      },
    },
  })

  const onSelectPlan = (newPlan) => setSelectedPlan(newPlan)

  const [activePayment, setActivePayment] = useState('credit_card')

  const [expandedAccordion, setExpandedAccordion] = useState(activePayment)
  const [selectedPaymentMethod, setselectedPaymentMethod] = useState('')

  const isPremiumExpiredSubscription =
    subscription?.status === 'premium_expired' ||
    subscriptionExpiredByCancellation

  const { onSubmit, isLoading } = usePayment({
    plan: selectedPlan,
    subscriptionTypeValidators: { isPremiumExpiredSubscription },
    isFromPaywallView: true,
  })

  const handleSubmit = (values) =>
    onSubmit({
      values,
      selectedPaymentMethod,
      isFromSubscriptionPaymentView: true,
    })

  const subscriptionViewContextValue = useMemo(() => {
    return {
      company,
      modality,
      selectedPlan,
      activePayment,
      expandedAccordion,
      isSubscriptionPromoPath,
      subscription: subscriptionQuery?.data,
      loadingPayment: isLoading,
      selectedPaymentMethod,
      onSelectPlan,
      setSelectedPlan,
      setModality,
      setActivePayment,
      setExpandedAccordion,
      setselectedPaymentMethod,
    }
  }, [
    company,
    modality,
    selectedPlan,
    activePayment,
    expandedAccordion,
    isSubscriptionPromoPath,
    subscriptionQuery?.data,
    isLoading,
    selectedPaymentMethod,
  ])

  useEffect(() => {
    if (!isSubscriptionPromoPath) {
      setModality(modalitySwitch || subscription?.type)
      setSelectedPlan(planSelected || currentPlan)
    }

    if (isSubscriptionPromoPath && isAFreeCompany) {
      setSelectedPlan(basicPlan)
    }
  }, [
    modalitySwitch,
    planSelected,
    isSubscriptionPromoPath,
    isAFreeCompany,
    basicPlan,
    subscription.plan,
    subscription?.type,
    currentPlan,
  ])

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus === 'DECLINED') {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  if (plansQuery.isLoading) {
    return <Loading />
  }

  const baseNavigationRoute =
    isAPartnerChild || isAFreeCompany || pseTransactionStatus === 'PENDING'
      ? DASHBOARD
      : SUBSCRIPTION_STATUS()

  const finalNavegationRoute = isPsePayment
    ? `${baseNavigationRoute}?pse_payment=true`
    : baseNavigationRoute

  return ((isSubscriptionPromoPath && isAFreeCompany) ||
    locationState ||
    subscriptionExpiredByCancellation) &&
    !['APPROVED', 'PENDING'].includes(pseTransactionStatus) ? (
    <SubscriptionViewContext.Provider value={subscriptionViewContextValue}>
      <Page
        documentTitle="Método de pago"
        rootSx={{
          ':after': { content: 'none' },
        }}
        contentSx={{ margin: '0 !important', height: '100%' }}
        isLoading={subscriptionQuery?.isLoading}
      >
        <Formik
          innerRef={formRef}
          onSubmit={handleSubmit}
          initialValues={getInitialValues(planSelected?.id, company)}
          validationSchema={getValidationSchema()}
          enableReinitialize
        >
          <Box
            sx={{
              height: '100%',
            }}
          >
            <SubscriptionSummary />
          </Box>
        </Formik>
      </Page>
    </SubscriptionViewContext.Provider>
  ) : (
    <Navigate to={finalNavegationRoute} />
  )
}

export default Paywall
