import { Typography } from '@mui/material'

import Emoji from 'components/UI/Emoji'
import Icon from 'components/UI/Icon'
import OrganizationEmail from 'components/UI/OrganizationEmail'

import { formatDisplayDate } from 'utils/dateTime'
import { cryEmoji, magnifyingGlass, sunglassesFace } from 'utils/emojis'
import { formatCurrency, getWordSingular } from 'utils/format'

export const getModalDialogue = ({
  subscriptionTypeValidators,
  transactionStatus,
  isExtraworkersPayment,
}) => {
  if (transactionStatus === 'DECLINED') {
    return 'declinedTransaction'
  }

  if (transactionStatus === 'PENDING') {
    return 'pendingTransaction'
  }

  if (isExtraworkersPayment) {
    return 'extraworkersPayment'
  }

  if (subscriptionTypeValidators?.currentPlanPayment) {
    return 'currentPlanPayment'
  }

  if (subscriptionTypeValidators?.isPremiumExpiredSubscription) {
    return 'isPremiumExpiredSubscription'
  }

  return 'plan'
}

export const modalDialogues = ({
  planName,
  isExtraworkersPayment,
  subscription,
}) => {
  const data = {
    declinedTransaction: (
      <>
        <Typography
          variant="h4"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          Qué triste <Emoji code={cryEmoji} />. Parece que tu método de pago es
          demasiado cool para nosotros
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          No pudimos realizar el cobro con el método de pago ingresado. ¡Pero no
          te preocupes! Esas cosas pasan.
          <br /> <br />
          Ingresa de nuevo los datos, y si el error persiste, escríbenos a{' '}
          <OrganizationEmail color="accent4.main" />.
        </Typography>
      </>
    ),
    pendingTransaction: (
      <>
        <Typography
          variant="h4"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          Tu pago está pendiente de aprobación por el banco{' '}
          <Emoji code={magnifyingGlass} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(3) })}
        >
          {isExtraworkersPayment ? (
            <>
              Tu pago está siendo procesado y una vez sea aprobado, podrás
              agregar a las nuevas personas.
              <br /> <br />
              Si tu dinero fue debitado y aún no puedes agregar personas,
              escríbenos por el chat o a{' '}
              <OrganizationEmail color="accent4.main" />.
            </>
          ) : (
            <>
              Tu pago está siendo procesado y podría tardar hasta 3 días en
              completarse. No te preocupes ¡Hasta a los mejores les pasa!
              <br /> <br />
              Mientras se aprueba, podrás seguir disfrutrando de todas las
              funcionalidades de tu plan.
            </>
          )}
        </Typography>
      </>
    ),
    isPremiumExpiredSubscription: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        >
          Ya puedes continuar llevándole felicidad a tu equipo con tu plan{' '}
          {planName}. A acontinuación, puedes ver los detalles de tu plan. Si
          tienes dudas, puedes escribirnos a{' '}
          <OrganizationEmail color="accent4.main" />.
        </Typography>
      </>
    ),
    currentPlanPayment: (
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(4) })}
      >
        Continúa llevándole felicidad a tu equipo con tu plan {planName}. A
        acontinuación, puedes ver los detalles de tu plan. Si tienes dudas,
        puedes escribirnos a <OrganizationEmail color="accent4.main" />.
      </Typography>
    ),
    plan: (
      <>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(4) })}
        >
          A continuación, podrás validar los detalles de tu nuevo plan{' '}
          {planName}. Si tienes dudas, puedes escribirnos a{' '}
          <OrganizationEmail color="accent4.main" />.
        </Typography>
      </>
    ),
    extraworkersPayment: (
      <>
        <Typography
          variant="h4"
          sx={(theme) => ({ marginBottom: theme.spacing(2) })}
        >
          La transacción fue exitosa.
          <Emoji code={sunglassesFace} />
        </Typography>
        <Typography
          color="black.dark"
          sx={(theme) => ({ marginBottom: theme.spacing(8) })}
        >
          Ahora puedes tener un total de {subscription?.paid_workers}{' '}
          {getWordSingular('personas', subscription?.paid_workers === 1)} en tu
          nómina.
          <br /> <br />
          Recuerda que siempre puedes agregar más cupos a tu equipo, para que
          nadie se quede por fuera y puedan disfrutar de todos los beneficios de
          Aleluya.
        </Typography>
      </>
    ),
  }

  return data
}

export const getSubscriptionInfoCardsData = ({
  downgradeData,
  subscription,
  totalPayment,
  paymentPeriodicity,
}) => {
  const { active_until: activeUntil, payrolls_size: workersNumber } =
    subscription || {}

  const { date } = downgradeData || {}

  const data = [
    {
      id: 'active_workers',
      label: 'Personas activas',
      value: (
        <>
          {workersNumber} {getWordSingular('personas', workersNumber === 1)}
        </>
      ),
      icon: <Icon name="female-and-male" />,
      backgroundColor: 'primary.main',
      adornment: 'asterisk',
    },
    {
      id: 'value',
      label: `Nuevo valor ${
        paymentPeriodicity === 'month' ? 'mensual' : 'anual'
      }`,
      value: formatCurrency(totalPayment),
      icon: <Icon name="document-coin" />,
      backgroundColor: 'complementary2.light',
    },
    {
      id: 'active_until',
      label: 'Fecha del próximo pago',
      value: formatDisplayDate(date || activeUntil),
      icon: <Icon name="calendar" />,
      backgroundColor: 'accent2.main',
    },
  ]

  return data
}
