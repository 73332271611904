import { defineElement } from 'lord-icon-element'
import { Element } from 'lord-icon-element/element'
import lottie from 'lottie-web'
import queryString from 'query-string'
import { useCallback, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Box } from '@mui/material'

import ElectronicPayrollAlertWrapper from 'components/Period/ElectronicPayroll/ElectronicPayrollAlert/ElectronicPayrollAlertWrapper'
import BulkNoveltiesAlertWrapper from 'components/Period/Payroll/BulkNoveltiesAlert/BulkNoveltiesAlertWrapper'
import SubscriptionAlertModal from 'components/Subscription/Atoms/SubscriptionAlertModal'
import useSubscriptionAlertModal from 'components/Subscription/Atoms/SubscriptionAlertModal/useSubscriptionAlertModal'
import UpdatePaymentMethodInfoModal from 'components/Subscription/Atoms/UpdatePaymentMethodInfoModal'
import useUpdatePaymentMethodInfoModal from 'components/Subscription/Atoms/UpdatePaymentMethodInfoModal/useUpdatePaymentMethodInfoModal'
import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Loading from 'components/UI/Loading/Loading'

import auth, {
  isOrganizer,
  isPremiumExpiredByCancellationCompany,
} from 'utils/auth'
import { getDiferenceOfDays } from 'utils/dateTime'
import { isTest } from 'utils/general'
import { useHasPendingOnboarding } from 'utils/onboarding'

import * as routes from 'config/routes'

import Header from './Header/Index'
import MobileAppBar from './MobileAppBar'
import AppSubscriptionStatusAlert from './Premium/SubscriptionStatusAlert'
import Sidebar from './Sidebar/Sidebar'
import SupportMenuButton from './SupportMenuButton/Index'
import { useUser } from './UserContext/useUser'

Element.setIconLoader(async (iconName) => {
  const iconData = await import(`assets/images/animated-icons/${iconName}.json`)

  return iconData
})
defineElement(lottie.loadAnimation)

const App = () => {
  const { isAuthenticated, company, isLoading, isAPartnerChild } = useUser()
  const { subscription } = useSubscription()
  const location = useLocation()
  const navigate = useNavigate()
  const [mobileOpen, setMobileOpen] = useState(false)
  const isOnboardingActive = location.pathname.includes('/onboarding')
  const queryParams = queryString.parse(location.search)
  const isOrganizerUser = isOrganizer()
  const {
    openSubscriptionAlertModal,
    closeSubscriptionAlertModal,
    subscriptionAlertModalState,
  } = useSubscriptionAlertModal()
  const {
    updatePaymentMethodInfoModalState,
    closeUpdatePaymentMethodInfoModal,
    openUpdatePaymentMethodInfoModal,
  } = useUpdatePaymentMethodInfoModal()

  const isInsideAuth = queryParams.password_recovered
  const hasTwoMonthsAway = getDiferenceOfDays(subscription?.active_until) <= 60

  const isCompanyImmediatePayment =
    subscription?.utm_campaign === 'Aleluya10mil' &&
    subscription.month_value === 0 &&
    !isOrganizerUser

  const subscriptionExpiredByCancellation =
    isPremiumExpiredByCancellationCompany(subscription)

  const isInSubscriptionStatusView =
    location.pathname === routes.SUBSCRIPTION_STATUS()

  const isInSubscriptionView = location.pathname === routes.SUBSCRIPTION()
  const isPendingPaymentStatus = subscription.payment_status === 'pending'

  // const showCountdownAlert =
  //   flagsReady &&
  //   companiesPromo10k?.value === 'on' &&
  //   !isAPartnerChild &&
  //   !subscription?.partner &&
  //   !company?.prepaid_promo

  const showSubscriptionStatusAlert =
    (!isOnboardingActive &&
      (['premium_expired', 'exonerated_payment'].includes(
        subscription?.status
      ) ||
        subscriptionExpiredByCancellation ||
        isPendingPaymentStatus) &&
      !auth.isAlegraClaroDist() &&
      !auth.isBancolombiaDist() &&
      !auth.isInnpulsaDist() &&
      !isInSubscriptionView) ||
    (subscription?.payment_category === null &&
      auth.isInnpulsaDist() &&
      hasTwoMonthsAway &&
      !isInSubscriptionStatusView)

  const handleDrawerToggle = useCallback(
    () => setMobileOpen((previousValue) => !previousValue),
    []
  )

  const pendingOnboarding = useHasPendingOnboarding(
    company?.onboarding?.onboarding_step
  )

  useEffect(() => {
    window.Appcues?.page()
  }, [location.pathname])

  useEffect(() => {
    if (pendingOnboarding.value && !isOnboardingActive) {
      navigate(pendingOnboarding.pathname, { replace: true })
    }

    if (
      company?.onboarding?.onboarding_step === 'finished' &&
      isCompanyImmediatePayment
    ) {
      navigate(routes.SUBSCRIPTION_END_FREE(), { replace: true })
    }
  }, [
    navigate,
    isOnboardingActive,
    pendingOnboarding.pathname,
    pendingOnboarding.value,
    isCompanyImmediatePayment,
    company?.onboarding?.onboarding_step,
  ])

  useEffect(() => {
    if (
      !isTest &&
      !isAPartnerChild &&
      localStorage.getItem('one_by_session_info_modal') !== 'modalViewed'
    ) {
      if (
        (['premium_expired', 'premium_due'].includes(subscription?.status) ||
          subscriptionExpiredByCancellation) &&
        !isPendingPaymentStatus
      ) {
        openSubscriptionAlertModal({
          subscriptionStatus: subscription.status,
          subscriptionExpiredByCancellation,
        })
      } else if (
        subscription?.payment_category === null &&
        !['exonerated_payment', 'premium_trial'].includes(subscription?.status)
      ) {
        openUpdatePaymentMethodInfoModal({
          actionMessage: 'invitation_to_update',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAPartnerChild,
    location.pathname,
    subscription?.payment_category,
    subscription.status,
    subscriptionExpiredByCancellation,
  ])

  const isInsideAppAuth =
    isInsideAuth && location.pathname === routes.PERIOD_PAY_PAYROLL()

  const isToSubscription = location.state?.toSubscription

  // if come from login and has onboarding pending, redirect to respective current step path
  if (location.state?.fromLogin && company.id) {
    let redirectTo = isToSubscription
      ? routes.SUBSCRIPTION_STATUS()
      : routes.PERIOD_PAY_PAYROLL()
    const { onboarding } = company

    // check onboarding_step to redirect
    if (pendingOnboarding.value) {
      redirectTo = pendingOnboarding.pathname
    } else {
      if (onboarding.test_payroll_processed_at)
        redirectTo = routes.WORKER_INDEX()
      if (!onboarding.test_workers) redirectTo = routes.PERIOD_PAY_PAYROLL()
      if (onboarding.first_payroll_processed_at) redirectTo = '/'
    }

    return <Navigate to={redirectTo} />
  }

  if (!isAuthenticated() && !isInsideAppAuth) {
    let redirect = location.pathname
    if (redirect !== '/' && redirect.startsWith('/')) {
      redirect = `${routes.LOGIN}?redirect=${redirect}${location.search}`
    } else {
      redirect = routes.LOGIN
    }

    return <Navigate to={redirect} />
  }

  return isLoading ? (
    <Loading />
  ) : (
    <Box
      sx={(theme) => ({
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '1fr',
        [theme.breakpoints.up('laptop')]: {
          gridTemplateColumns: 'auto 1fr',
        },
      })}
    >
      {pendingOnboarding?.value && !isOrganizerUser ? null : (
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          isCompanyImmediatePayment={isCompanyImmediatePayment}
          openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
        />
      )}
      <Box
        component="main"
        sx={(theme) => ({
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          minWidth: 0,
          overflow: 'auto',
          paddingTop: !isOnboardingActive ? theme.mobileAppbar : 0,
          [theme.breakpoints.up('laptop')]: {
            paddingTop: 0,
          },
        })}
      >
        {!isOnboardingActive ? (
          <MobileAppBar
            mobileOpen={mobileOpen}
            handleDrawerToggle={handleDrawerToggle}
            isCompanyImmediatePayment={isCompanyImmediatePayment}
            openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
          />
        ) : null}
        {!isOnboardingActive ? (
          <Header isCompanyImmediatePayment={isCompanyImmediatePayment} />
        ) : null}
        {showSubscriptionStatusAlert ? (
          <AppSubscriptionStatusAlert
            isInSubscriptionStatusView={isInSubscriptionStatusView}
          />
        ) : null}
        {subscriptionAlertModalState.open ? (
          <SubscriptionAlertModal
            state={subscriptionAlertModalState}
            handleClose={closeSubscriptionAlertModal}
          />
        ) : null}
        {updatePaymentMethodInfoModalState.open ? (
          <UpdatePaymentMethodInfoModal
            state={updatePaymentMethodInfoModalState}
            handleClose={closeUpdatePaymentMethodInfoModal}
            openUpdatePaymentMethodInfoModal={openUpdatePaymentMethodInfoModal}
          />
        ) : null}
        {/* {showCountdownAlert ? <EndFreeCountdown /> : null} */}
        <ElectronicPayrollAlertWrapper>
          <BulkNoveltiesAlertWrapper>
            <Outlet />
          </BulkNoveltiesAlertWrapper>
        </ElectronicPayrollAlertWrapper>
        <SupportMenuButton />
      </Box>
    </Box>
  )
}

export default App
