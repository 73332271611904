import BankField from 'components/UI/Formik/CommonFields/BankField'

import AccountHolderIdentificationFields from '../SubscriptionFormFields/AccountHolderIdentificationFields'
import ContainerFields from '../SubscriptionFormFields/ContainerFields'
import AccountFields from './Account'
import TermsAcceptanceCard from './TermsAcceptanceCard'

const AutomaticDebitFields = ({ isEditingPaymentMethod }) => {
  return (
    <>
      <ContainerFields>
        <AccountHolderIdentificationFields />
        <BankField provider="druo" name="automatic_debit_form.bank" />
        <AccountFields
          accountTypeFieldName="automatic_debit_form.account_type"
          accountNumberFieldName="automatic_debit_form.account_number"
        />
        <TermsAcceptanceCard
          paymentVariant={
            isEditingPaymentMethod ? 'payment_data_update' : 'new_payment'
          }
        />
      </ContainerFields>
    </>
  )
}

export default AutomaticDebitFields
