import {
  Box,
  Button,
  ButtonBase,
  Typography,
  useMediaQuery,
} from '@mui/material'

import Icon from 'components/UI/Icon'

import DocumentsMan from 'assets/images/views/common/aleluya_documents_man_right.svg'

import useSubscription from '../useSubscription'
import {
  getModalDialogue,
  getSubscriptionInfoCardsData,
  modalDialogues,
} from './helpers'

const TransactionResponseModal = ({
  subscriptionTypeValidators,
  handleClose,
  transactionStatus,
  isExtraworkersPayment,
  showCloseButton,
  downgradeData,
}) => {
  const { subscription } = useSubscription()
  const isTrasactionApproved = transactionStatus === 'APPROVED'

  const paymentPeriodicity = downgradeData
    ? downgradeData.current_type === 'month'
      ? 'monthly'
      : 'yearly'
    : subscription.type === 'month'
      ? 'monthly'
      : 'yearly'

  const totalPayment = downgradeData
    ? downgradeData.plan_value
    : paymentPeriodicity === 'monthly'
      ? subscription?.month_value
      : subscription?.year_value

  const planName = downgradeData?.plan || subscription?.plan?.name

  const subscriptionInfoCardsData = getSubscriptionInfoCardsData({
    downgradeData,
    subscription,
    totalPayment,
    paymentPeriodicity,
  })

  const modalDialogue = modalDialogues({
    planName,
    isExtraworkersPayment,
    subscription,
  })

  const switcher = getModalDialogue({
    subscriptionTypeValidators,
    transactionStatus,
    isExtraworkersPayment,
  })

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('tablet'))

  return (
    <Box sx={{ display: 'relative' }}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            mobile: '1fr',
            tablet: !showCloseButton ? '1fr' : '18rem 1fr',
          },
        }}
      >
        {showCloseButton ? (
          <ButtonBase
            onClick={handleClose}
            sx={(theme) => ({
              position: 'absolute',
              cursor: 'pointer',
              color: theme.palette.black.main,
              right: theme.spacing(-1),
              top: theme.spacing(0),
            })}
          >
            <Icon name="close" sx={{ fontSize: '1.7rem' }} />
          </ButtonBase>
        ) : null}
        {!isMobile && (!isTrasactionApproved || isExtraworkersPayment) ? (
          <>
            <Box
              sx={(theme) => ({
                backgroundColor: theme.palette.accent2.light,
                width: '27.75rem',
                height: '27.75rem',
                borderRadius: '100%',
                position: 'absolute',
                left: theme.spacing(-18.5),
                top: theme.spacing(-8.75),
              })}
            />
            <Box
              component="img"
              alt="Con Aleluya tienes superpoderes para "
              src={DocumentsMan}
              width={200}
              height={293}
              sx={{
                objectFit: 'contain',
                zIndex: 1,
              }}
            />
          </>
        ) : null}
        <Box
          sx={(theme) => ({
            marginRight: theme.spacing(2.5),
            [theme.breakpoints.up('tablet')]: {
              ...((!isTrasactionApproved || isExtraworkersPayment) && {
                marginLeft: theme.spacing(4),
              }),
            },
          })}
        >
          {modalDialogue[switcher]}
          {!isTrasactionApproved || isExtraworkersPayment ? (
            <Button
              size="large"
              onClick={handleClose}
              sx={(theme) => ({ padding: theme.spacing(0, 10) })}
            >
              {transactionStatus === 'PENDING' || isExtraworkersPayment
                ? 'Cerrar'
                : 'Intentar de nuevo'}
            </Button>
          ) : null}
        </Box>
      </Box>
      {isTrasactionApproved && !isExtraworkersPayment ? (
        <>
          <Box>
            <Box
              sx={(theme) => ({
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                columnGap: theme.spacing(3),
                rowGap: theme.spacing(3),
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: '1fr',
                },
              })}
            >
              {subscriptionInfoCardsData?.map((transactionResponse) => {
                const { id, icon, label, value, backgroundColor } =
                  transactionResponse
                return (
                  <Box
                    key={id}
                    sx={(theme) => ({
                      position: 'relative',
                      backgroundColor,
                      borderRadius: '1rem',
                      padding: theme.spacing(1.5, 2, 2),
                      height: '6.5rem',
                      overflow: 'hidden',
                      [theme.breakpoints.down('tablet')]: {
                        maxWidth: '100%',
                      },
                    })}
                  >
                    <Box
                      sx={(theme) => ({
                        display: 'flex',
                        marginBottom: theme.spacing(1),
                        alignItems: 'center',
                        columnGap: theme.spacing(0.5),
                      })}
                    >
                      {icon}
                      <Typography variant="body2" sx={{ zIndex: 1 }}>
                        {label}
                      </Typography>
                    </Box>
                    <Typography variant="h2">{value}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
          <Box
            sx={(theme) => ({
              display: 'flex',
              marginTop: theme.spacing(7),
              [theme.breakpoints.down('tablet')]: {
                justifyContent: 'center',
                alignSelf: 'flex-end',
              },
            })}
          >
            <Button
              size="large"
              onClick={handleClose}
              sx={(theme) => ({ padding: theme.spacing(0, 10) })}
            >
              Finalizar
            </Button>
          </Box>
        </>
      ) : null}
    </Box>
  )
}

export default TransactionResponseModal
