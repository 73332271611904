import { useSnackbar } from 'notistack'

import { Typography } from '@mui/material'

import useDownloadURI from 'utils/hooks/useDownloadURI'
import useNotifications from 'utils/hooks/useNotifications'

import { downloadFileWebSocket } from 'services/httpService'

// object used to allow only one file_code download at a time and track the status of each one
const downloads = {}
let idleReadyDownloads = 0

function getDocumentTitle() {
  let documentTitle = document.title
  const endParentesisIdx = documentTitle.indexOf(') ')

  // if has "(#)" text, remove it
  if (endParentesisIdx !== -1)
    documentTitle = documentTitle.substring(endParentesisIdx + 2)

  return documentTitle
}

function getTitles(name, plural = false, gender = 'male') {
  let tu = 'tu'
  let esta = 'está'
  let este = 'esté'
  let listo = gender === 'male' ? 'listo' : 'lista'
  let encuentralo = gender === 'male' ? 'Encuéntralo' : 'Encuéntrala'

  if (plural) {
    tu += 's '
    esta += 'n'
    este += 'n'
    listo += 's'
    encuentralo += 's'
  }

  const titles = {
    starting: (
      <Typography variant="body2" component="span">
        Aleluya! Estamos generando {tu} <b>{name}</b>. Te notificaremos cuando{' '}
        {este} {listo}.
      </Typography>
    ),
    ready: (
      <Typography variant="body2" component="span">
        {tu[0].toUpperCase() + tu.slice(1)} <b>{name}</b> {esta} {listo}.{' '}
        {encuentralo} en tus descargas.
      </Typography>
    ),
    error: (
      <Typography variant="body2" component="span">
        Hubo un error al generar {tu} <b>{name}</b>. Por favor, inténtalo
        nuevamente. Si el problema persiste contacta a soporte.
      </Typography>
    ),
  }

  return titles
}

document.addEventListener('visibilitychange', () => {
  if (!document.hidden) {
    idleReadyDownloads = 0
    document.title = getDocumentTitle()
  }
})

const useDownloadManager = () => {
  const {
    showInfoMessage,
    showWarningMessage,
    showErrorMessage,
    showSuccessMessage,
  } = useNotifications()
  const { closeSnackbar } = useSnackbar()
  const downloadURI = useDownloadURI()

  const increaseIdleReadyDownloads = () => {
    if (document.hidden) {
      idleReadyDownloads += 1

      const documentTitle = getDocumentTitle()

      document.title = `(${idleReadyDownloads}) ${documentTitle}`
    }
  }

  const enqueueDownload = async ({
    name,
    description,
    fileCode,
    firebasePath,
    service,
    callback,
    namePlural,
    nameGender,
    handleError,
  }) => {
    if (downloads[fileCode] && downloads[fileCode].status === 'starting') {
      showWarningMessage('Ya existe una descarga en curso de ese archivo.', {
        autoHideDuration: 3000,
      })
      // this means that there is already a download of that file
      return
    }
    if (
      downloads[fileCode]?.status === 'ready' ||
      downloads[fileCode]?.status === 'error'
    ) {
      // close the previouss snackbar same file_code
      closeSnackbar(downloads[fileCode].snackbar_id)
    } else {
      downloads[fileCode] = { status: 'starting' }
    }

    const titles = getTitles(name, namePlural, nameGender)

    const initSnackbarId = showInfoMessage(titles.starting, {
      persist: true,
      inProgress: true,
    })
    const closeInitialSnackbar = () => closeSnackbar(initSnackbarId)
    const clearDownload = () => delete downloads[fileCode]

    try {
      // Get the result from the service that triggers the download. It can contain a file url or a "Procesando" message for a background file
      const serviceResponse = await service()
      let fileSocketResponse

      // If there's a firebase path, the file will be generated in a job and the final url will be sent through a websocket
      if (firebasePath) {
        fileSocketResponse = await downloadFileWebSocket(firebasePath)
      }

      // At the end, each kind of response should return the url in a "file" key
      const downloadResult = firebasePath ? fileSocketResponse : serviceResponse

      if (downloadResult.file) {
        downloads[fileCode] = { status: 'ready' }
        increaseIdleReadyDownloads()
        closeInitialSnackbar()
        const handleDownload = () => {
          downloadURI(downloadResult.file)
          if (callback) callback()
          clearDownload()
        }
        const handleClose = () => {
          clearDownload()
        }
        const downloadSnackbarId = showSuccessMessage(titles.ready, {
          ...(description && {
            description:
              typeof description === 'function'
                ? description(downloadResult)
                : description,
            onClose: handleClose,
          }),
          autoHideDuration: 20000,
        })
        downloads[fileCode].snackbar_id = downloadSnackbarId
        handleDownload()
        handleClose()
      } else {
        clearDownload()
        closeInitialSnackbar()
      }
    } catch (error) {
      if (handleError) {
        const wasErrorHandled = handleError(error)

        if (!wasErrorHandled) {
          showErrorMessage(titles.error)
        }
      } else {
        showErrorMessage(titles.error)
      }

      downloads[fileCode] = { status: 'error' } // unnecessary
      clearDownload()
      closeInitialSnackbar()
    }
  }

  return {
    enqueueDownload,
  }
}

export default useDownloadManager
