import cardValidator from 'card-validator'
import * as yup from 'yup'

import { Box } from '@mui/material'

import Icon from 'components/UI/Icon'

import { yupLocaleES } from 'utils/form'

import { getCurrentPaymentMethods } from '../Index/PaymentMethod/helpers'
import SubscriptionAutomaticDebitFields from './AutomaticDebitFields'
import SubscriptionCreditCardFields from './CreditCardFields'
import SubscriptionPSEFields from './PSEFields'

yup.setLocale(yupLocaleES)

export const getPaymentFields = (isEditingPaymentMethod = false) => ({
  automatic_debit: {
    fields: (
      <SubscriptionAutomaticDebitFields
        isEditingPaymentMethod={isEditingPaymentMethod}
      />
    ),
  },
  credit_card: {
    fields: (
      <SubscriptionCreditCardFields
        isEditingPaymentMethod={isEditingPaymentMethod}
      />
    ),
  },
  pse: {
    fields: <SubscriptionPSEFields />,
  },
  bank_transfer: {
    fields: <SubscriptionPSEFields />,
  },
})

export const dirtyPaymentValues = (values, paymentMethod, plan) => {
  let dirtyValues

  if (paymentMethod === 'credit_card') {
    dirtyValues = {
      card_number: values.card_number,
      security_code: values.security_code,
      payer_name: values.payer_name,
      payment_type: plan.type,
    }

    const { expiration_date: expirationDate } = values

    dirtyValues.expiration_date =
      // MMYYYY => YYYY/MM
      `${expirationDate.substring(2)}/${expirationDate.substring(0, 2)}`
  }

  if (paymentMethod === 'pse') {
    dirtyValues = {
      account_type: values.account_type,
      account_number: values.account_number,
      bank: values.bank?.id,
      client_type: values.client_type,
      id_number: values.id_number,
      payment_type: plan.type,
    }
  }

  if (paymentMethod === 'automatic_debit') {
    dirtyValues = {
      document_type: values.document_type,
      id_number: values.id_number,
      account_type: values.account_type,
      account_number: values.account_number,
      bank: values.bank?.id,
    }
  }

  dirtyValues.plan_id = plan.id
  dirtyValues.payment_type = plan.payment_type

  if (plan.paid_extra_workers)
    dirtyValues.paid_extra_workers = plan.paid_extra_workers

  return dirtyValues
}

const creditCardFormSchema = yup
  .object()
  .shape({
    payer_name: yup.string().nullable().trim(),
    card_number: yup.number().nullable(),
    expiration_date: yup.string().nullable().trim(),
    security_code: yup.string().nullable().trim(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'credit_card',
    then: (schema) =>
      schema.shape({
        payer_name: yup.string().trim().nullable().required(),
        card_number: yup
          .number()
          .nullable()
          .required()
          .test(
            'test-number',
            'Número de tarjeta de crédito inválido',
            (value) => cardValidator.number(value).isValid
          ),
        expiration_date: yup
          .string()
          .nullable()
          .required()
          .test(
            'test-expiration-year',
            'Debe ser una fecha válida',
            (value) => {
              return cardValidator.expirationDate(value).isValid
            }
          ),
        security_code: yup
          .string()
          .nullable()
          .required()
          .test(
            'test-number',
            'Código de seguridad inválido',
            function (value) {
              const type = cardValidator.number(this.parent.card_number).card
                ?.type
              return cardValidator.cvv(
                `${value}`,
                type === 'american-express' ? 4 : 3
              ).isValid
            }
          ),
      }),
  })

const automaticDebitFormSchema = yup
  .object()
  .shape({
    document_type: yup.string().nullable(),
    id_number: yup.string().nullable(),
    bank: yup.object().nullable(),
    account_type: yup.string().nullable(),
    account_number: yup.string().nullable(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'automatic_debit',
    then: (schema) =>
      schema.shape({
        document_type: yup.string().nullable().required(),
        id_number: yup.string().nullable().required(),
        bank: yup.object().nullable().required(),
        account_type: yup.string().nullable().required(),
        account_number: yup.string().nullable().required(),
      }),
  })

const pseFormSchema = yup
  .object()
  .shape({
    client_type: yup.string().nullable(),
    bank: yup.object().nullable(),
  })
  .when('payment_method', {
    is: (paymentMethod) => paymentMethod === 'pse',
    then: (schema) =>
      schema.shape({
        client_type: yup.string().nullable().required(),
        bank: yup.object().nullable().required(),
      }),
  })

export const getSchemaValidation = (isCurrentMethodPayment) => {
  let schemaValidation

  if (!isCurrentMethodPayment) {
    schemaValidation = yup.object({
      credit_card_form: creditCardFormSchema,
      automatic_debit_form: automaticDebitFormSchema,
      pse_form: pseFormSchema,
      payment_method: yup.string().nullable().required(),
    })
  }

  return schemaValidation
}

export const getInitialValues = (isCurrentMethodPayment = false) => ({
  credit_card_form: {
    payer_name: null,
    card_number: null,
    expiration_date: null,
    security_code: null,
  },
  automatic_debit_form: {
    bank: null,
    account_type: null,
    account_number: null,
    document_type: null,
    id_number: null,
  },
  pse_form: {
    bank: null,
    client_type: '',
  },
  is_current_payment_method: isCurrentMethodPayment,
  payment_method: 'credit_card',
})

export const getTabsData = (
  subscription,
  isEditingPaymentMethod,
  currentPlanPayment
) => {
  const {
    payment_methods: availablePaymentMethods,
    subscription_payment_methods_info: currentPaymentMethodsInfo,
  } = subscription

  const {
    credit_card: showCreditCardMethod,
    automatic_debit: automaticDebitMethodAvailable,
    pse: showPseMethod,
  } = availablePaymentMethods || {}

  const currentPaymentMethods = getCurrentPaymentMethods(
    currentPaymentMethodsInfo
  )

  // In case of immediate payment required, shows automatic debit only when the bank account is already connected or is a current plan payment
  const showAutomaticDebitMethod =
    (currentPaymentMethods?.includes('automatic_debit') ||
      isEditingPaymentMethod ||
      currentPlanPayment) &&
    automaticDebitMethodAvailable

  const availablePaymentMethodsNumber = Object.keys(
    availablePaymentMethods || {}
  ).length

  const tabs = []

  // Show credit card when available or when no other payment methods are available
  if (showCreditCardMethod || availablePaymentMethodsNumber === 0)
    tabs.push({
      key: 'credit_card',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="smart-lock-card"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Tarjeta débito/crédito
        </Box>
      ),
    })

  if (showAutomaticDebitMethod)
    tabs.push({
      key: 'automatic_debit',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refresh"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          Débito automático
        </Box>
      ),
    })

  // Hide pse when editing current payment method
  if (showPseMethod && !isEditingPaymentMethod)
    tabs.push({
      key: 'pse',
      label: (
        <Box display="flex" alignItems="center">
          <Icon
            name="refund"
            sx={(theme) => ({
              marginRight: theme.spacing(0.5),
            })}
          />
          PSE
        </Box>
      ),
    })

  return tabs
}

export const paymentModality = {
  month: 'mensual',
  year: 'anual',
}

export const getInitialCopy = (paymentVariant, paymentMethods) => {
  if (paymentVariant === 'new_payment') {
    return 'Al completar este pago'
  }
  if (paymentVariant === 'payment_data_update') {
    return `Al ${paymentMethods?.length ? 'actualizar' : 'agregar'} tu método de pago`
  }
  if (paymentVariant === 'first_payment') {
    return 'Al pagar tu suscripción'
  }

  return 'Al completar esta acción'
}
