import DocumentSignatureFields from './DocumentSignature'
import EditPeriodHistory from './EditPeriodHistory'
import PaymentConfiguration from './PaymentConfiguration'
import UVTConfiguration from './UVTConfiguration'
import WorkHoursConfiguration from './WorkHoursConfiguration'

const AdvancedSetttingsFormFields = ({ options = {}, step }) => {
  switch (step) {
    case 'payment_settings': {
      return (
        <>
          <PaymentConfiguration options={options} />
          <EditPeriodHistory options={options.edit_periods} />
        </>
      )
    }

    case 'working_time':
      return <WorkHoursConfiguration />
    case 'uvt':
      return <UVTConfiguration options={options.uvt_configuration} />
    case 'signature_documents':
      return <DocumentSignatureFields options={options} />
    default:
      return null
  }
}

export default AdvancedSetttingsFormFields
