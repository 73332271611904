import { useQueryClient } from 'react-query'

import { Box, Button, Typography } from '@mui/material'

import useModals from 'components/App/ModalsManager/useModals'
import { useUser } from 'components/App/UserContext/useUser'
import useTransactionResponseModal from 'components/Subscription/Atoms/TransactionResponseModal/useTransactionResponseModal'
import Loading from 'components/UI/Loading/Loading'

import { getCompanyId } from 'utils/company'
import { formatLongDisplayDate } from 'utils/dateTime'
import { formatCurrency } from 'utils/format'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'
import useNotifications from 'utils/hooks/useNotifications'

const SubscriptionConfirmationModal = ({
  selectedPlan,
  selectedModality,
  isAnUpgrade,
  isCurrentPlan,
  activeUntil,
  planUpgrade,
  isChangingModality,
}) => {
  const { refreshCompany, isAPartnerChild } = useUser()
  const { showSuccessMessage } = useNotifications()

  const paymentPeriodicity = selectedModality === 'month' ? 'monthly' : 'yearly'
  const subscriptionTypeValidators = {
    isCurrentPlan,
    isChangingModality,
  }

  const { subscriptionQuery, subscriptionMutation } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getFullPaymentValue', getCompanyId()],
      newPlanId: selectedPlan?.id,
      subscriptionType: selectedModality,
    },
  })

  const totalPayment =
    subscriptionQuery.data?.subscription_value[paymentPeriodicity]?.total

  const modals = useModals()
  const queryClient = useQueryClient()
  const { openTransactionResponseModal } = useTransactionResponseModal()

  const handleUpdateSubscription = () => {
    subscriptionMutation.mutate(
      {
        mutationMethod: 'PUT',
        mutationKey: 'updateSubscription',
        newPlanId: selectedPlan?.id,
        subscriptionType: selectedModality,
      },
      {
        onSuccess: async ({ data: downgradeData }) => {
          await refreshCompany()
          queryClient.invalidateQueries(['getPlans', getCompanyId()])
          modals.closeModal('confirmSubscriptionModal')
          if (isAPartnerChild) {
            showSuccessMessage(
              <>
                La modificación de tu plan fue exitosa.{' '}
                {planUpgrade
                  ? '¡Ya mismo puedes empezar a disfrutar de tus nuevos superpoderes!'
                  : 'Recuerda que los cambios se verán reflejados al finalizar el presente ciclo.'}
              </>
            )
          } else {
            openTransactionResponseModal({
              subscriptionTypeValidators,
              downgradeData,
            })
          }
        },
        onError: () => {
          modals.closeModal('confirmSubscriptionModal')
        },
      }
    )
  }

  const getButtonText = () => {
    if (isAPartnerChild) return 'Activar plan'
    if (isAnUpgrade) return 'Mejorar mi plan'

    return 'Cambiar mi plan'
  }

  return !totalPayment ? (
    <Loading />
  ) : (
    <Box>
      <Typography
        color="black.dark"
        sx={(theme) => ({ marginBottom: theme.spacing(5) })}
      >
        {!planUpgrade ? (
          <>
            La actualización se hará efectiva al finalizar tu actual periodo de
            facturación, el <b>{formatLongDisplayDate(activeUntil)}.</b>
          </>
        ) : (
          'La activación de los superpoderes en tu nuevo plan será inmediata.'
        )}
      </Typography>
      {!isAPartnerChild ? (
        <Typography color="black.dark">
          Recuerda que el nuevo valor{' '}
          <b>
            {selectedModality === 'month' ? 'mensual' : 'anual'} a pagar es{' '}
            {formatCurrency(totalPayment)}.
          </b>
        </Typography>
      ) : null}
      <Box
        sx={(theme) => ({
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: theme.spacing(2),
          marginTop: theme.spacing(6),
          flexWrap: 'wrap',
          [theme.breakpoints.up('tablet')]: {
            justifyContent: 'flex-start',
          },
        })}
      >
        <Button
          size="large"
          onClick={() => handleUpdateSubscription()}
          sx={(theme) => ({ padding: theme.spacing(0, 5) })}
        >
          {getButtonText()}
        </Button>
        <Button
          size="large"
          variant="outlined"
          onClick={() => modals.closeAll()}
          sx={(theme) => ({ padding: theme.spacing(0, 9) })}
        >
          Cancelar
        </Button>
      </Box>
    </Box>
  )
}

export default SubscriptionConfirmationModal
