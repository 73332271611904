import { withSentryReactRouterV6Routing } from '@sentry/react'
import { Navigate, Route, Routes } from 'react-router-dom'

import UnlockAccount from 'components/Auth/UnlockAccount'
import ElectronicPayrollWorkerHistoryView from 'components/Period/ElectronicPayroll/ElectronicPayrollWorkerHistory'
import SettingsAccounting from 'components/Settings/Accounting/Accounting'
import SettingsIntegrationAccounting from 'components/Settings/Integration/Accounting/Accounting'
import ContractDetailView from 'components/Worker/Contract/Detail'

import DistributorLoginView from 'views/Auth/DistributorLogin'
import LoginView from 'views/Auth/Login'
import LogoutView from 'views/Auth/Logout'
import OnboardingFirstSteps from 'views/Auth/Onboarding/FirstSteps'
import Onboarding from 'views/Auth/Onboarding/Index'
import PasswordRecoveryView from 'views/Auth/PasswordRecovery'
import PasswordResetView from 'views/Auth/PasswordReset'
import SingupView from 'views/Auth/Signup'
import WorkerBenefitsView from 'views/Benefits/Index'
import CompanyFormView from 'views/Company/Form'
import CompanyIndexView from 'views/Company/Index'
import CompanyShowView from 'views/Company/Show'
import CompanyRoute from 'views/CompanyRoute'
import DashboardView from 'views/Dashboard'
import DisabilitiesClaimsView from 'views/HolisticPayroll/DesabilitiesClaims'
import FinancialCoachingView from 'views/HolisticPayroll/FinancialCoaching'
import NoveltiesRequestIndex from 'views/NoveltiesRequest/Index'
import OrganizerAffiliationsIndex from 'views/Organizer/Affiliations/Index'
import OrganizerCompaniesView from 'views/Organizer/Company/Index'
import OrganizerCompanyPaymentsView from 'views/Organizer/Company/Payment/Form'
import OrganizerFilesView from 'views/Organizer/Files/Files'
import OrganizerPartnerIndexView from 'views/Organizer/Partner/Index'
import OrganizerPartnerPaymentFormView from 'views/Organizer/Partner/Payment/Form'
import OrganizerPartnerShowView from 'views/Organizer/Partner/Show'
import OrganizerUsersView from 'views/Organizer/Users/Index'
import OrganizerRoute from 'views/OrganizerRoute'
import Decree376View from 'views/Period/Decree376'
import PeriodDetailsView from 'views/Period/Details'
import ElectronicPayrollView from 'views/Period/ElectronicPayroll'
import NoveltiesHistoryView from 'views/Period/NoveltiesHistory'
import PayrollView from 'views/Period/Payroll'
import PeriodHistoryView from 'views/Period/PeriodHistory'
import SocialBenefitsView from 'views/Period/SocialBenefits'
import SocialSecurityView from 'views/Period/SocialSecurity'
import SocialSecurityPaymentHistory from 'views/Period/SocialSecurityPaymentHistory'
import SocialSecurityTransactionResponse from 'views/Period/SocialSecurityTransactionResponse'
import SettingsAdvancedView from 'views/Settings/Advanced'
import SettingsElectronicPayrollView from 'views/Settings/ElectronicPayroll'
import SettingsHistory from 'views/Settings/History'
import SettingsIntegrationView from 'views/Settings/Integration'
import SettingsProfileView from 'views/Settings/Profile'
import SettingsView from 'views/Settings/Settings'
import SettingsUsersView from 'views/Settings/Users'
import SettingsWorkersBenefitsView from 'views/Settings/WorkersBenefits'
import SubscriptionPaymentsView from 'views/Subscription/Payments'
import SubscriptionPaywallView from 'views/Subscription/Paywall'
import SubscriptionStatusView from 'views/Subscription/Status'
import ContractIndexView from 'views/Worker/Contract/Index'
import ContractTerminationForm from 'views/Worker/Contract/TerminationForm'
import WageIndex from 'views/Worker/Contract/WageIndex'
import WorkerFormView from 'views/Worker/Form'
import HolidaysView from 'views/Worker/HolidaysView'
import WorkerImportView from 'views/Worker/Import/Import'
import WorkerIndexView from 'views/Worker/Index'
import LoanIndex from 'views/Worker/Loan/Index'
import LoanShow from 'views/Worker/Loan/Show'
import PaymentHistoryView from 'views/Worker/PaymentHistory'
import WorkerShowView from 'views/Worker/Show'
import WorkerSocialBenefitsIndex from 'views/Worker/SocialBenefits/Index'
import WorkerSocialBenefitsShow from 'views/Worker/SocialBenefits/Show'
import WorkersWagesView from 'views/Worker/WagesUpdate'
import WorkerProfileRoute from 'views/WorkerProfileRoute'

import useFeatureFlags from 'utils/hooks/useFeatureFlags'

import * as routes from 'config/routes'

import App from './App'
import RouteNotFound from './Error/NotFound'
import PaymentRequired from './Error/PaymentRequired'
import NotificationsView from './Header/CourierNotifications/View'
import { useUser } from './UserContext/useUser'

const SentryRoutes = withSentryReactRouterV6Routing(Routes)

const AppRoutes = () => {
  const { active, workerWithBenefits, isWorker, isOrganizer, user } = useUser()

  const { humanResourcesSA, flagsReady } = useFeatureFlags({
    flags: ['humanResourcesSA'],
    trackingMode: 'attributes',
    attributes: {
      userEmail: user.email,
    },
  })

  let nextRoute

  if (isOrganizer) {
    nextRoute = routes.ORGANIZER_COMPANY_INDEX()
  } else if (isWorker) {
    if (!active && workerWithBenefits) {
      nextRoute = routes.WORKER_PROFILE_BENEFITS()
    } else {
      nextRoute = routes.WORKER_PROFILE_SHOW()
    }
  } else {
    nextRoute = routes.DASHBOARD
  }

  return (
    <SentryRoutes>
      {[routes.LOGIN, routes.LOGIN_2, routes.LOGIN_OAUTH].map((path) => (
        <Route key={path} path={path} element={<LoginView />} />
      ))}
      <Route
        path={routes.DISTRIBUTOR_LOGIN}
        element={<DistributorLoginView />}
      />
      {[routes.SIGN_UP, routes.SIGN_UP_2, routes.SIGN_UP_3].map((path) => (
        <Route key={path} path={path} element={<SingupView />} />
      ))}
      <Route
        path={routes.PASSWORD_RECOVERY}
        element={<PasswordRecoveryView />}
      />
      <Route path={routes.PASSWORD_RESET} element={<PasswordResetView />} />
      <Route path={routes.UNLOCK_ACCOUNT} element={<UnlockAccount />} />
      <Route path={routes.LOGOUT} element={<LogoutView />} />
      <Route element={<App />}>
        <Route path="/" element={<Navigate to={nextRoute} />} />
        <Route path="/organizer">
          <Route index element={<Navigate to="companies" />} />
          <Route
            path="companies"
            element={
              <OrganizerRoute>
                <OrganizerCompaniesView />
              </OrganizerRoute>
            }
          />
          <Route path="companies">
            <Route
              index
              element={
                <OrganizerRoute>
                  <OrganizerCompaniesView />
                </OrganizerRoute>
              }
            />
            <Route
              path=":companyId/payment"
              element={
                <OrganizerRoute>
                  <OrganizerCompanyPaymentsView />
                </OrganizerRoute>
              }
            />
          </Route>
          <Route
            path="users"
            element={
              <OrganizerRoute>
                <OrganizerUsersView />
              </OrganizerRoute>
            }
          />
          <Route
            path="actions"
            element={
              <OrganizerRoute>
                <OrganizerFilesView />
              </OrganizerRoute>
            }
          />
          <Route path="partners">
            <Route
              index
              element={
                <OrganizerRoute>
                  <OrganizerPartnerIndexView />
                </OrganizerRoute>
              }
            />
            <Route
              path=":partnerId"
              element={
                <OrganizerRoute>
                  <OrganizerPartnerShowView />
                </OrganizerRoute>
              }
            />
            <Route
              path=":partnerId/payment"
              element={
                <OrganizerRoute>
                  <OrganizerPartnerPaymentFormView />
                </OrganizerRoute>
              }
            />
          </Route>
          {flagsReady && humanResourcesSA?.value === 'on' ? (
            <>
              <Route path="affiliations">
                <Route
                  index
                  element={
                    <OrganizerRoute>
                      <OrganizerAffiliationsIndex />
                    </OrganizerRoute>
                  }
                />
                <Route
                  path=":affiliationId"
                  element={
                    <OrganizerRoute>
                      <WorkerShowView />
                    </OrganizerRoute>
                  }
                />
              </Route>
            </>
          ) : null}
        </Route>
        {[
          routes.ONBOARDING_WELCOME(),
          routes.ONBOARDING_ROLE(),
          routes.ONBOARDING_PERSONALIZE(),
          routes.ONBOARDING_CONGRATULATIONS(),
        ].map((path) => (
          <Route
            key={path}
            path={path}
            element={
              <CompanyRoute>
                <Onboarding />
              </CompanyRoute>
            }
          />
        ))}
        <Route
          path="/notifications"
          element={
            <CompanyRoute>
              <NotificationsView />
            </CompanyRoute>
          }
        />
        <Route
          path="/payment_required"
          element={
            <CompanyRoute>
              <PaymentRequired />
            </CompanyRoute>
          }
        />
        <Route
          path="/first_steps"
          element={
            <CompanyRoute>
              <OnboardingFirstSteps />
            </CompanyRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <CompanyRoute>
              <DashboardView />
            </CompanyRoute>
          }
        />
        <Route
          path="/novelties_request_history"
          element={
            <CompanyRoute>
              <NoveltiesRequestIndex />
            </CompanyRoute>
          }
        />
        <Route
          path="/user/profile"
          element={
            <WorkerProfileRoute>
              <SettingsProfileView />
            </WorkerProfileRoute>
          }
        />
        <Route path="/payments_history">
          <Route
            index
            element={
              <WorkerProfileRoute>
                <PaymentHistoryView />
              </WorkerProfileRoute>
            }
          />
          <Route
            path=":payrollId/view_details"
            element={
              <WorkerProfileRoute>
                <PeriodDetailsView />
              </WorkerProfileRoute>
            }
          />
        </Route>
        <Route
          path="/profile"
          element={
            <WorkerProfileRoute>
              <WorkerShowView />
            </WorkerProfileRoute>
          }
        />
        {/* <Route
          path="/benefits"
          element={
            <WorkerProfileRoute>
              <WorkerBenefitsView />
            </WorkerProfileRoute>
          }
        /> */}
        <Route path="/pay_payroll">
          <Route
            index
            element={
              <CompanyRoute>
                <PayrollView />
              </CompanyRoute>
            }
          />
          <Route
            path="generated_payroll"
            element={
              <CompanyRoute>
                <PayrollView />
              </CompanyRoute>
            }
          />
          <Route path=":periodId">
            <Route index element={<Navigate to="/pay_payroll" />} />
            <Route
              path="novelties_history"
              element={
                <CompanyRoute>
                  <NoveltiesHistoryView />
                </CompanyRoute>
              }
            />
            <Route
              path="decree376/:decree376Id"
              element={
                <CompanyRoute>
                  <Decree376View />
                </CompanyRoute>
              }
            />
            <Route
              path="social_security"
              element={
                <CompanyRoute>
                  <SocialSecurityView />
                </CompanyRoute>
              }
            />
            <Route
              path="social_benefits_period/:socialBenefitPeriodId"
              element={
                <CompanyRoute>
                  <SocialBenefitsView />
                </CompanyRoute>
              }
            />
          </Route>
          <Route path=":payrollId">
            <Route index element={<Navigate to="/pay_payroll" />} />
            <Route
              path="view_details/:socialBenefitsId"
              element={
                <CompanyRoute>
                  <PeriodDetailsView />
                </CompanyRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/periods_history">
          <Route
            index
            element={
              <CompanyRoute>
                <PeriodHistoryView />
              </CompanyRoute>
            }
          />
          <Route path=":periodId">
            <Route
              index
              element={
                <CompanyRoute>
                  <PayrollView payPreviousPayroll />
                </CompanyRoute>
              }
            />
            <Route
              path="generated_payroll"
              element={
                <CompanyRoute>
                  <PayrollView payPreviousPayroll />
                </CompanyRoute>
              }
            />
            <Route
              path="novelties_history"
              element={
                <CompanyRoute>
                  <NoveltiesHistoryView />
                </CompanyRoute>
              }
            />
            <Route
              path="social_benefits_period/:socialBenefitPeriodId"
              element={
                <CompanyRoute>
                  <SocialBenefitsView />
                </CompanyRoute>
              }
            />
            <Route
              path="decree376/:decree376Id"
              element={
                <CompanyRoute>
                  <Decree376View />
                </CompanyRoute>
              }
            />
            <Route
              path="social_security"
              element={
                <CompanyRoute>
                  <SocialSecurityView />
                </CompanyRoute>
              }
            />
            <Route path=":payrollId">
              <Route index element={<Navigate to="/periods_history" />} />
              <Route
                path="view_details/:socialBenefitsId"
                element={
                  <CompanyRoute>
                    <PeriodDetailsView />
                  </CompanyRoute>
                }
              />
            </Route>
          </Route>
          <Route path="electronic_payroll/:periodId">
            <Route
              index
              element={
                <CompanyRoute>
                  <ElectronicPayrollView />
                </CompanyRoute>
              }
            />
            <Route path="worker_history">
              <Route index element={<Navigate to=".." relative="path" />} />
              <Route
                path=":workerId"
                element={
                  <CompanyRoute>
                    <ElectronicPayrollWorkerHistoryView />
                  </CompanyRoute>
                }
              />
            </Route>
          </Route>
        </Route>
        <Route
          path="/social_security_integration_payment/transaction_response"
          element={
            <CompanyRoute>
              <SocialSecurityTransactionResponse />
            </CompanyRoute>
          }
        />
        <Route path="/workers">
          <Route
            index
            element={
              <CompanyRoute>
                <WorkerIndexView />
              </CompanyRoute>
            }
          />
          <Route
            path="new"
            element={
              <CompanyRoute>
                <WorkerFormView />
              </CompanyRoute>
            }
          />
          <Route
            path="import"
            element={
              <CompanyRoute>
                <WorkerImportView />
              </CompanyRoute>
            }
          />
          <Route
            path="benefits"
            element={
              <CompanyRoute>
                <WorkerBenefitsView />
              </CompanyRoute>
            }
          />
          <Route
            path="wages"
            element={
              <CompanyRoute>
                <WorkersWagesView />
              </CompanyRoute>
            }
          />
          <Route path=":workerId">
            <Route
              index
              element={
                <CompanyRoute>
                  <WorkerShowView />
                </CompanyRoute>
              }
            />
            <Route
              path="payments_history"
              element={
                <CompanyRoute>
                  <PaymentHistoryView />
                </CompanyRoute>
              }
            />
            <Route
              path="holidays_history"
              element={
                <CompanyRoute>
                  <HolidaysView />
                </CompanyRoute>
              }
            />
            <Route
              path="novelties_history"
              element={
                <CompanyRoute>
                  <NoveltiesHistoryView />
                </CompanyRoute>
              }
            />
            <Route path="loans">
              <Route
                index
                element={
                  <CompanyRoute>
                    <LoanIndex />
                  </CompanyRoute>
                }
              />
              <Route
                path=":loanId"
                element={
                  <CompanyRoute>
                    <LoanShow />
                  </CompanyRoute>
                }
              />
            </Route>
            <Route path="contracts">
              <Route
                index
                element={
                  <CompanyRoute>
                    <ContractIndexView />
                  </CompanyRoute>
                }
              />
              <Route path=":contractId">
                <Route index element={<Navigate to=".." relative="path" />} />
                <Route
                  path="detail"
                  element={
                    <CompanyRoute>
                      <ContractDetailView />
                    </CompanyRoute>
                  }
                />
                <Route
                  path="wages"
                  element={
                    <CompanyRoute>
                      <WageIndex />
                    </CompanyRoute>
                  }
                />
                <Route path="termination">
                  <Route
                    index
                    element={<Navigate to="../.." relative="path" />}
                  />
                  <Route
                    path="new"
                    element={
                      <CompanyRoute>
                        <ContractTerminationForm />
                      </CompanyRoute>
                    }
                  />
                  <Route
                    path="edit"
                    element={
                      <CompanyRoute>
                        <ContractTerminationForm isEditing />
                      </CompanyRoute>
                    }
                  />
                </Route>
                <Route path="social_benefits">
                  <Route
                    index
                    element={
                      <CompanyRoute>
                        <WorkerSocialBenefitsIndex />
                      </CompanyRoute>
                    }
                  />
                  <Route
                    path=":socialBenefitId"
                    element={
                      <CompanyRoute>
                        <WorkerSocialBenefitsShow />
                      </CompanyRoute>
                    }
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/companies">
          <Route index element={<CompanyIndexView />} />
          <Route path=":companyId">
            <Route index element={<CompanyShowView />} />
            <Route path="edit" element={<CompanyFormView />} />
          </Route>
        </Route>
        <Route path="/subscription">
          <Route
            index
            element={
              <CompanyRoute>
                <SubscriptionPaywallView />
              </CompanyRoute>
            }
          />
          <Route
            path="end_free"
            element={
              <CompanyRoute>
                <SubscriptionPaywallView />
              </CompanyRoute>
            }
          />
        </Route>
        <Route path="/subscription_detail">
          <Route
            index
            element={
              <CompanyRoute>
                <SubscriptionStatusView />
              </CompanyRoute>
            }
          />
          <Route
            path="payments"
            element={
              <CompanyRoute>
                <SubscriptionPaymentsView />
              </CompanyRoute>
            }
          />
        </Route>
        <Route path="/settings">
          <Route
            index
            element={
              <CompanyRoute>
                <SettingsView />
              </CompanyRoute>
            }
          />
          <Route
            path="advanced"
            element={
              <CompanyRoute>
                <SettingsAdvancedView />
              </CompanyRoute>
            }
          />
          <Route
            path="users"
            element={
              <CompanyRoute>
                <SettingsUsersView />
              </CompanyRoute>
            }
          />
          <Route
            path="workers_benefits"
            element={
              <CompanyRoute>
                <SettingsWorkersBenefitsView />
              </CompanyRoute>
            }
          />
          <Route
            path="company_history"
            element={
              <CompanyRoute>
                <SettingsHistory />
              </CompanyRoute>
            }
          />
          <Route
            path="accounting"
            element={
              <CompanyRoute>
                <SettingsAccounting />
              </CompanyRoute>
            }
          />
          <Route
            path="electronic_payroll"
            element={
              <CompanyRoute>
                <SettingsElectronicPayrollView />
              </CompanyRoute>
            }
          />
          <Route path="integrations">
            <Route
              index
              element={
                <CompanyRoute>
                  <SettingsIntegrationView />
                </CompanyRoute>
              }
            />
            <Route
              path="alegra/accounting"
              element={
                <CompanyRoute>
                  <SettingsIntegrationAccounting />
                </CompanyRoute>
              }
            />
            <Route
              path="social_security_payments_history"
              element={
                <CompanyRoute>
                  <SocialSecurityPaymentHistory />
                </CompanyRoute>
              }
            />
          </Route>
        </Route>
        <Route path="/holistic_payroll">
          <Route
            index
            path="disabilities_claims"
            element={
              <CompanyRoute>
                <DisabilitiesClaimsView />
              </CompanyRoute>
            }
          />
          <Route
            path="financial_coaching"
            element={
              <WorkerProfileRoute>
                <FinancialCoachingView />
              </WorkerProfileRoute>
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<RouteNotFound />} />
    </SentryRoutes>
  )
}
export default AppRoutes
