import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import usePsePaymentCheck from 'components/Subscription/Atoms/usePsePaymentCheck'
import Emoji from 'components/UI/Emoji'
import Page from 'components/UI/Page/Page'

import { getCompanyId } from 'utils/company'
import { isChristmasTime } from 'utils/dateTime'
import { fatherChristmas, wavingHand } from 'utils/emojis'
import useDashboardService from 'utils/hooks/dashboardService'

import ComingSoonEvents from './CommingSoon'
import NextPayments from './NextPayments'
import Pendings from './PendingsTable'
import TeamStats from './TeamStats'
import UpdateCompanyPhoneModal from './UpdateCompanyPhoneModal'
import useUpdateCompanyPhoneModal from './UpdateCompanyPhoneModal/useUpdateCompanyPhoneModal'

const Dashboard = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const isPsePayment = queryParams.get('pse_payment') === 'true'

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()

  const { closeUpdateCompanyPhoneModal, updateCompanyPhoneModalState } =
    useUpdateCompanyPhoneModal()

  const messageQueryKey = [
    'dashboardByMetrics',
    'greet_message',
    getCompanyId(),
  ]

  const { dashboardQuery: messageQuery } = useDashboardService({
    serviceParams: {
      queryKey: messageQueryKey,
      metrics: 'greet_message',
    },
  })

  const workersInformationQueryKey = [
    'dashboardByMetrics',
    'workers_information',
    getCompanyId(),
  ]

  const { dashboardQuery: workersInformationQuery } = useDashboardService({
    serviceParams: {
      queryKey: workersInformationQueryKey,
      metrics: 'workers_information',
    },
  })

  const {
    anniversaries = [],
    birthdays = [],
    contracts_to_expire: contractsToExpire = [],
    team_count: teamCount = {},
    workers_off_duty: workersOffDuty = {},
  } = workersInformationQuery.data?.workers_information || {}

  // Variable for add personalize message christmas
  const christmasGreetMessage = isChristmasTime() ? (
    <>
      Jo, jo, jo <Emoji code={fatherChristmas} />
    </>
  ) : null

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus) {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  return (
    <Page
      documentTitle="Torre de control"
      header={
        messageQuery.data?.greet_message ? (
          <Box sx={{ gridColumn: '1 / -1' }}>
            <Typography variant="h2">
              {christmasGreetMessage}
              {messageQuery.data?.greet_message}
              <Box
                component="span"
                sx={(theme) => ({
                  marginLeft: theme.spacing(0.5),
                })}
              >
                <Emoji code={wavingHand} />
              </Box>
            </Typography>
          </Box>
        ) : null
      }
      isLoading={messageQuery?.isLoading}
      grid
    >
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridColumn: '1 / -1',
          rowGap: theme.spacing(3),
          columnGap: theme.spacing(3),
          gridTemplateColumns: 'repeat(4, 1fr)',
          [theme.breakpoints.up('tablet')]: {
            gridTemplateColumns: 'repeat(6, 1fr)',
            columnGap: theme.spacing(4),
          },
          [theme.breakpoints.up('laptop')]: {
            gridTemplateColumns: 'repeat(12, 1fr)',
            rowGap: theme.spacing(6),
            gridTemplateRows: 'auto auto auto',
            gridAutoRows: 'max-content',
            position: 'relative',
          },
        })}
      >
        <NextPayments />
        <TeamStats
          teamCount={teamCount}
          workersOffDuty={workersOffDuty}
          isLoading={workersInformationQuery.isLoading}
        />
        <Pendings />
        <ComingSoonEvents
          anniversaries={anniversaries}
          birthdays={birthdays}
          contractsToExpire={contractsToExpire}
          isLoading={workersInformationQuery.isLoading}
        />
      </Box>
      {updateCompanyPhoneModalState.open ? (
        <UpdateCompanyPhoneModal
          state={updateCompanyPhoneModalState}
          handleClose={closeUpdateCompanyPhoneModal}
        />
      ) : null}
    </Page>
  )
}

export default Dashboard
