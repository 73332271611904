import { useEffect, useRef } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import { useUser } from 'components/App/UserContext/useUser'
import { FrequentQuestions } from 'components/Subscription/Index/helpers'
import Page from 'components/UI/Page/Page'

import { isFreeCompany, isOrganizer } from 'utils/auth'
import { getCompanyId } from 'utils/company'
import useSubscriptionService from 'utils/hooks/subscription/subscriptionService'

import * as routes from 'config/routes'

import usePsePaymentCheck from '../usePsePaymentCheck'
import useSubscription from '../useSubscription'
import ActiveUntilCard from './ActionGroup/ActiveUntilCard'
import BillingCard from './ActionGroup/BillingCard'
import CurrentPlan from './ActionGroup/CurrentPlan'
import PaymentMethodCard from './ActionGroup/PaymentMethodCard'
import SeePaymentsCard from './ActionGroup/SeePaymentsCard'
import UpcomingDowngradeAlertModal from './UpcomingDowngradeAlertModal'
import useUpcomingDowngradeAlertModal from './UpcomingDowngradeAlertModal/useUpcomingDowngradeAlertModal'

const ShowSubscription = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location?.search)
  const isPsePayment = queryParams.get('pse_payment') === 'true'
  const frequentQuestionsRef = useRef()
  const { isAPartnerChild } = useUser()
  const { subscription } = useSubscription()

  const { openPseTransactionResponseModal, pseTransactionStatus } =
    usePsePaymentCheck()
  const {
    upcomingDowngradeAlertModalState,
    closeUpcomingDowngradeAlertModal,
    openUpcomingDowngradeAlertModal,
  } = useUpcomingDowngradeAlertModal()

  const companyId = getCompanyId()

  const { subscriptionQuery: plansQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getPlans', companyId],
    },
  })

  const { subscriptionQuery: downgradeQuery } = useSubscriptionService({
    serviceParams: {
      queryKey: ['getDowngradeInfo', companyId],
    },
    queryOptions: {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  })

  const isAFreeCompany = isFreeCompany(
    subscription?.status,
    subscription?.payment_status
  )

  useEffect(() => {
    if (isPsePayment && pseTransactionStatus) {
      openPseTransactionResponseModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPsePayment, pseTransactionStatus])

  useEffect(() => {
    if (
      downgradeQuery?.data?.downgrade_subscription_info &&
      localStorage.getItem('upcoming_downgrade_alert_modal') !== 'modalViewed'
    ) {
      openUpcomingDowngradeAlertModal({
        downgradeData: downgradeQuery?.data?.downgrade_subscription_info,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downgradeQuery?.data])

  if (
    (isAFreeCompany ||
      isAPartnerChild ||
      subscription.payment_category === 'distributor_payment') &&
    !isOrganizer()
  ) {
    return <Navigate to={routes.DASHBOARD} replace />
  }

  const plansData = plansQuery?.data?.plans
  const currentPlan = plansData?.find((plan) => plan.current_plan === true)

  const showActiveUntilCard =
    ['premium_canceled', 'premium_expired'].includes(subscription?.status) &&
    subscription?.payment_status !== 'pending'

  return (
    <Page grid>
      <Typography
        variant="h2"
        sx={(theme) => ({
          margin: theme.spacing(0, 0, 4),
        })}
      >
        Suscripción
      </Typography>
      <Box
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '1fr',
          rowGap: theme.spacing(3),
          gridColumn: '1 / -1',
        })}
      >
        <CurrentPlan
          subscriptionData={subscription}
          currentPlan={currentPlan}
        />
        <Box
          sx={(theme) => ({
            display: 'grid',
            gridTemplateColumns: '1fr',
            rowGap: theme.spacing(3),
            [theme.breakpoints.up('tablet')]: {
              gridTemplateColumns: '1fr 1fr',
              columnGap: theme.spacing(4),
            },
            [theme.breakpoints.up('laptop')]: {
              gridTemplateColumns: '1fr 1fr 1fr',
              rowGap: 0,
            },
          })}
        >
          <Box
            sx={(theme) => ({
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            })}
          >
            {showActiveUntilCard ? <ActiveUntilCard /> : null}
            <PaymentMethodCard subscriptionData={subscription} />
          </Box>
          <BillingCard />
          <SeePaymentsCard />
        </Box>
        <Box
          sx={(theme) => ({
            margin: theme.spacing(2, 0, 6),
          })}
        >
          <FrequentQuestions
            forwardedRef={frequentQuestionsRef}
            filterIndexes={[0]}
          />
        </Box>
      </Box>
      {upcomingDowngradeAlertModalState.open ? (
        <UpcomingDowngradeAlertModal
          state={upcomingDowngradeAlertModalState}
          handleClose={closeUpcomingDowngradeAlertModal}
        />
      ) : null}
    </Page>
  )
}

export default ShowSubscription
