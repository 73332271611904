import { useCallback, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Box, Typography } from '@mui/material'

import useDownloadManager from 'components/App/DownloadManager/useDownloadManager'
import { useUser } from 'components/App/UserContext/useUser'
import PayslipModal from 'components/Period/Payroll/Modals/PayslipModal'
import usePayslipModal from 'components/Period/Payroll/Modals/usePayslipModal'
import Link from 'components/UI/MaterialUI/Link'
import Page from 'components/UI/Page/Page'
import Table from 'components/UI/Table/Table'
import useWorker from 'components/Worker/useWorker'

import { getWorkerId } from 'utils/worker'

import workerProfileService from 'services/worker/workerProfileService'
import workerService from 'services/worker/workerService'

import * as routes from 'config/routes'

import { getColumnsData, getTableActions } from './helpers'

const PaymentHistory = () => {
  const params = useParams()
  const navigate = useNavigate()
  const { isWorker } = useUser()
  const { worker } = useWorker()

  const [isLoading, setIsLoading] = useState(false)
  const { enqueueDownload } = useDownloadManager()

  const {
    payslipModalState,
    openPayslipModal,
    closePayslipModal,
    handleDownloadPayslip,
  } = usePayslipModal()

  const hasPayslipsAccess = worker.workers_payslips_access
  const [service, workerId] = isWorker
    ? [workerProfileService, getWorkerId()]
    : [workerService, params.workerId]

  const fetchPayments = (pageIndex) => {
    return {
      queryKey: ['getWorkerPayments', pageIndex + 1],
      queryFunction: () =>
        service.getPaymentsHistory(workerId, {
          page: pageIndex + 1,
          async: false,
        }),
    }
  }

  const handleClickNoveltyReport = () => {
    enqueueDownload({
      name: 'reporte de pagos salariales',
      fileCode: 'salary_payments_file',
      firebasePath: `/workers/${workerId}/payments_history`,
      service: () =>
        service.getPaymentsHistory(workerId, { file_format: 'xlsx' }),
    })
  }

  const showPayslip = useCallback(
    async (payment) => {
      setIsLoading(true)
      if (payment.payslip_options.length > 0) {
        await openPayslipModal({
          payroll: { id: payment.payroll_id },
          payslipTypes: payment.payslip_options,
        })
      } else {
        await handleDownloadPayslip({ payroll: { id: payment.payroll_id } })
      }
      setIsLoading(false)
    },
    [handleDownloadPayslip, openPayslipModal]
  )

  const columnsData = getColumnsData()

  const tableActions = getTableActions({
    handleClickNoveltyReport,
    showPayslip,
    navigate,
    hasPayslipsAccess,
  })

  return (
    <Page
      documentTitle={`Historial de Pagos${
        worker && !isWorker ? ` de ${worker.fullName}` : ''
      }`}
      header={
        <Typography
          variant="h2"
          sx={{
            gridColumn: '1 / -1',
          }}
        >
          Historial de Pagos de{' '}
          {worker ? (
            <Link to={routes.WORKER_SHOW(workerId)}>{worker.fullName}</Link>
          ) : (
            'persona'
          )}
        </Typography>
      }
      isLoadingWithModal={isLoading}
      grid
    >
      <Box
        sx={{
          gridColumn: '1 / -1',
        }}
      >
        <Table
          columns={columnsData}
          data={fetchPayments}
          actions={tableActions}
          options={{
            search: false,
            version: {
              toolbar: 'v2',
            },
          }}
        />
      </Box>
      {payslipModalState.open ? (
        <PayslipModal
          state={payslipModalState}
          handleClose={closePayslipModal}
          handleDownloadPayslip={handleDownloadPayslip}
        />
      ) : null}
    </Page>
  )
}

export default PaymentHistory
