import { Form, Formik } from 'formik'
import * as yup from 'yup'

import { Box, Typography } from '@mui/material'

import useSubscription from 'components/Subscription/Atoms/useSubscription'
import Button from 'components/UI/Button/Button'
import FormField from 'components/UI/Formik/FormField/Index'
import Icon from 'components/UI/Icon'
import HeaderImage from 'components/UI/Modal/HeaderImage'

import { formatDisplayDateString } from 'utils/dateTime'
import { yupLocaleES } from 'utils/form'
import { formatCurrency, getWordSingular } from 'utils/format'
import { isObjectEmpty } from 'utils/general'

import ilustrationError from 'assets/images/views/common/aleluya_beach_man.svg'

import { useCalculateExtraWorkers } from '../../Index/helpers'
import usePaymentModal from '../PaymentModal/usePaymentModal'

yup.setLocale(yupLocaleES)

const getValidationSchema = (initialExtraWorkerNumber) => {
  return yup.object({
    extra_workers_number: yup
      .number()
      .nullable()
      .min(
        initialExtraWorkerNumber,
        `El número mínimo de personas es ${initialExtraWorkerNumber}`
      )
      .required(),
  })
}

export const ModalExtraWorker = ({ onClose }) => {
  const { paidWorkers, additionalWorkersInfo } = useSubscription()
  const { calculateSubscriptionFromInput } = useCalculateExtraWorkers()
  const paymentModal = usePaymentModal()

  const validationSchema = getValidationSchema(
    additionalWorkersInfo?.extra_workers_number
  )

  const handlePayment = (value) => {
    paymentModal.openModal({
      extraWorkers: value,
      isFromExtraWorkersModal: true,
    })
  }

  const initialDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_initial_day
  )

  const endDay = formatDisplayDateString(
    additionalWorkersInfo?.subscription_end_day
  )

  return (
    <Formik
      enableReinitialize
      initialValues={{
        extra_workers_number: additionalWorkersInfo?.extra_workers_number || 0,
        pricing_value: additionalWorkersInfo?.extra_workers_value || 0,
      }}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }) => {
        const callback = (data) =>
          setFieldValue(
            'pricing_value',
            data?.additional_workers_info?.extra_workers_value
          )
        const handleOnChange = (event) =>
          calculateSubscriptionFromInput(
            { values: event.target.value },
            callback
          )

        return (
          <>
            <Typography variant="body1">
              Vas a superar el límite de{' '}
              <b>
                {paidWorkers} {getWordSingular('personas', paidWorkers === 1)}
              </b>{' '}
              con el que actualmente cuentas en tu plan anual. Para agregar más
              personas debes realizar un pago. El cobro se hará proporcional al
              tiempo restante de tu suscripción.
            </Typography>
            <Box sx={(theme) => ({ marginTop: theme.spacing(3) })}>
              <Typography variant="lead1">
                ¿Cuántas personas quieres agregar?
              </Typography>
              <Form>
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    width: '11.563rem',
                    alignItems: 'center',
                    columnGap: theme.spacing(1),
                  })}
                >
                  <FormField
                    variant="number"
                    name="extra_workers_number"
                    onChange={handleOnChange}
                    sx={{
                      color: 'primary.main',
                      fontSize: '1.5rem',
                    }}
                  />
                  <Typography color="primary" variant="h6">
                    Personas
                  </Typography>
                </Box>
              </Form>
            </Box>
            <Box
              sx={(theme) => ({
                marginTop: theme.spacing(2.5),
                display: 'grid',
                gridTemplateColumns: '55% 42%',
                gap: theme.spacing(2.5),
                gridTemplateRows: '6.25rem',
                [theme.breakpoints.down('sm')]: {
                  gridTemplateColumns: '100%',
                },
              })}
            >
              <Box>
                <Typography variant="lead1">Periodo del plan</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    height: '3.125rem',
                    backgroundColor: 'white.light',
                    padding: '0.625rem',
                    borderRadius: '0.5rem',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Icon name="clock-time" />
                  <Typography variant="lead1">{initialDay}</Typography>
                  <Icon name="arrow-right" />
                  <Typography variant="lead1">{endDay}</Typography>
                </Box>
              </Box>
              <Box>
                <Typography variant="lead1">Total a pagar</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    height: '3.125rem',
                    backgroundColor: 'white.light',
                    padding: '0.625rem',
                    borderRadius: '0.5rem',
                    alignItems: 'center',
                  }}
                >
                  <Typography color="primary" variant="h5">
                    {formatCurrency(values.pricing_value)}
                  </Typography>
                </Box>
                <Typography color="primary" variant="small">
                  *Incluye descuento suscripción anual
                </Typography>
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                display: 'flex',
                columnGap: theme.spacing(2),
                margin: theme.spacing(3, 0, 4),
              })}
            >
              <Button
                disabled={!isObjectEmpty(errors)}
                onClick={() => handlePayment(values.extra_workers_number)}
              >
                Agregar{' '}
                {getWordSingular(
                  'personas',
                  values.extra_workers_number === '1'
                )}
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Cancelar
              </Button>
            </Box>
          </>
        )
      }}
    </Formik>
  )
}

export const Header = () => {
  return (
    <HeaderImage
      image={ilustrationError}
      title="Agrega más Personas a tu Plan Anual"
    />
  )
}
